.page_main_header {
    width: 100%;
    margin: auto;
    max-width: 1325px;
    margin-block-start: 28rem;

    @include mobile {
        width: unset;
        padding: 0 14px;
        margin: 0%
    }

    @include tablet {
        width: unset;
        padding: 0 14px;
    }

    .page_header {
        font-weight: 700;
        line-height: 1.1;
        font-size: 2.59615385rem;
        margin-block-start: 0;
        margin-block-end: 2.88461538rem;
        text-align: start;
        margin-inline-start: 6rem;

        @include mobile {
            margin-inline-start: 0;
        }
    }

    .page_para {
        margin-block-start: 0;
        margin-block-end: 1.44230769rem;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 3;
        margin-inline-start: 6rem;
        color: #212529;
        border-bottom: 1px solid #d1d1d1;
        text-align: start;

        @include mobile {
            margin-inline-start: 0;
            line-height: 2;
        }
    }
}
