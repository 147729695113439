/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.PasswordChangePage {
    background-color: #f5f5f5;

    .PasswordChangePageSection {
        @include desktop {
            padding-block-start: 65px;
        }

        @include mobile {
            padding: 65px 10px;
        }
    }

    &-Wrapper {
        background-color: #fff;
        display: grid;

        @include desktop {
            padding: 100px 50px;
            max-width: 1200px;
            grid-template-columns: 1fr 1fr;
            gap: 3rem;
            align-items: center;
            border-radius: 4px;
        }

        @include mobile {
            padding: 28px;
            gap: 4rem;

            h1 {
                font-size: 16px;
                text-align: center;
                margin-block-end: 4rem;
            }
        }

        input {
            width: 100%;
            height: 100%;
            background-color: #f5f5f5;
            border: none;
            box-shadow: inset 1px 1px 6px #00000014;
            caret-color: #000;
        }

        button {
            @include button;

            margin-block-start: 12px;
        }
    }

    &-PasswordChangeForm {
        @include desktop {
            width: 460px;

            h1 {
                font-size: 20px;
                text-align: center;
            }
        }

        .PasswordChangeForm-Button {
            width: 100%;
            margin-block-start: 4rem;
            border-radius: 4px;
        }
    }

    .Field {
        &-Label {
            font-size: 16px;
        }
    }

    &-PasswordChangeFormImg {
        display: flex;
        justify-content: center;

        @include mobile {
            svg {
                width: 120px;
                height: 120px;
            }
        }
    }

    &-Button {
        margin-block-start: 12px;

        @include mobile {
            margin-block-start: 14px;
            width: 100%;
        }
    }

    &-WarningMsg {
        padding-block-start: 20px;
        text-align: center;
        color: var(--primary-error-color);
        font-size: 15px;

        h2 {
            color: var(--primary-error-color);
        }
    }
}
