/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --color-pagination-link-text: var(--imported_pagination_color, #{$black});
    --color-pagination-link-active-text: var(--primary-base-color);
    --pagination-line-height: var(--imported_pagination_line_height, 20px);
    --pagination-font-size: var(--imported_pagination_font_size, 14px);
    --pagination-font-style: var(--imported_pagination_font_style, normal);
}

.PaginationLink {
    border: 1px solid #ededed;
    border-radius: 7px;
    color: var(--color-pagination-link-text);
    font-size: var(--pagination-font-size);
    font-style: var(--pagination-font-style);
    display: block;
    line-height: var(--pagination-line-height);
    margin-inline-end: 15px;
    padding: 7px 13px;
    text-align: center;

    @include mobile {
        font-size: 12px;
        padding: 2px 8px;
        margin-inline-end: 9px;
    }

    &_isArrow {
        padding: 7px 11px;
        border: none;

        @include mobile {
            padding: 6px 0px; 
        }
    }

    &_isArrowInActive {
        pointer-events: none;
        opacity: 0.5;
    }

    &:hover {
        --color-pagination-link-text: var(--color-pagination-link-active-text);

        text-decoration: none;
    }

    &_isCurrent {
        --color-pagination-link-text: var(--color-white);
        
        border-color: var(--color-pagination-link-active-text);
        background-color: var(--color-pagination-link-active-text);
        box-shadow: 2px 3px 6px #E73B2533;

        &:hover {
            color: var(--color-white);
        }
    }
}
