.Download-Container {
    max-width: 1060px;
    margin-inline: auto;
    margin-block-start: 24px;

    @include mobile {
        padding: 0 16px;
    }

    .Download-Wrapper {
        .download-banner {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 6%;

            .bannerContainer {
                .download-img-cnt {
                    .download-img {
                        object-fit: contain;
                        width: 100%;
                    }
                }
            }

            .banner-content {
                margin-block-start: 24px;

                .banner-headding {
                    font-size: 24px;
                    margin: 0;
                }

                .text-content {
                    margin: 0;
                    margin-block-start: 20px;
                    font-size: 14px;
                    line-height: 22px;

                    &.second {
                        padding-inline-end: 10px;
                    }
                }
            }

            @include mobile {
                display: flex;
                flex-direction: column;

                .banner-content {
                    margin-block-start: 20px;
                    
                    .banner-headding {
                        text-align: start;
                        font-size: 18px;
                    }

                    .text-content {
                        font-size: 12px;
                    }
                }
            }
        }

        .download-grid-wrapper {
            margin-block: 136px;

            .autoOrder-Headding {
                display: none;
            }

            .download-grid {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(2, 199px);
                gap: 44px;

                .download-card-Wrapper {
                    box-shadow: 1px 2px 10px #0000000D;
                    border-radius: 4px;

                    &.first {
                        box-shadow: none;
                    }

                    .card-container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        &.headding {
                            padding-block-start: 70px;
                            padding-inline-end: 90px;
                        }

                        .icon {
                            margin: 36px 0 24px 0;
                        }

                        .text {
                            font-size: 14px;
                            line-height: 22px;
                            text-align: center;
                            padding-inline: 18px;
                            color: #0f0f0f;
                        }
                    }
                }
            }

            @include mobile {
                margin-block: 36px;

                .autoOrder-Headding {
                    display: block;
                    
                    h2 {
                        margin: 0;
                        margin-block-end: 20px;
                        text-align: start;
                    }
                }

                .download-grid {
                    display: flex;
                    gap: 16px;
                    overflow: auto;
                    padding-block-end: 20px;
                    padding-block-start: 4px;

                    &::-webkit-scrollbar {
                        min-width: 324px;
                        height: 2px;
                    }

                    &::-webkit-scrollbar-track {
                        background-color: #EEEEEE;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #E43D2F;
                    }

                    .download-card-Wrapper {
                        width: 100%;
                        min-width: 324px;

                        &.first {
                            display: none;
                        }

                        .card-container {
                            &.headding {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .dropshipping-container {
            .dropshipping-wrapper {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                column-gap: 24px;

                .dropship-content {
                    margin-block-start: 96px;

                    .dropship-headding {
                        font-size: 24px;
                        margin-block-end: 24px;
                    }

                    .dropship-text {
                        font-size: 14px;
                        line-height: 22px;
                    }
                }

                .dropship-img-cnt {
                    .dropship-img-cnt {
                        .shipping-img {
                            width: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }

            @include mobile {
                .dropshipping-wrapper {
                    display: flex;
                    flex-direction: column;

                    .dropship-content {
                        margin: 0;
                        
                        .dropship-headding {
                            font-size: 18px;
                            text-align: start;
                            margin: 0;
                            margin-block-end: 16px;
                        }

                        .dropship-text {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .grid-tiles-wrapper {
            margin-block-start: 48px;

            .tiles-inner {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                column-gap: 44px;
                row-gap: 52px;

                .tile {
                    padding: 32px 0;
                    text-align: center;
                    box-shadow: 1px 3px 12px #0000000f;
                    border-radius: 4px;

                    .tiles-text {
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                    }
                }
            }

            @include mobile {
                margin-block-start: 24px;

                .tiles-inner {
                    grid-template-columns: 1fr;
                    row-gap: 16px;

                    .tile {
                        padding: 20px 0;
                        text-align: start;

                        .tiles-text {
                            font-size: 12px;
                            padding-inline-start: 16px;
                            
                            br {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .after-tilesWrapper {
            margin-block-start: 136px;

            .innerWrapper {
                background-color: #f2faf8;
                text-align: center;
                padding: 32px 80px;

                .textcontainer {
                    color: #094A53;
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 32px;
                    margin-block-end: 0;
                }
            }

            @include mobile {
                margin-block-start: 36px;

                .innerWrapper {
                    padding: 20px 22px;
                    text-align: start;

                    .textcontainer {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}

.downloadsForm {
    max-width: var(--content-wrapper-width);
    margin: 0 auto;
}
