/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyViewQuotePopup {
    .Popup {
        &-Content {
            max-width: 600px;
            overflow-y: visible;
        }

        &-Header {
            border-block-end: 1px solid var(--primary-divider-color);
        }

        &-Heading {
            font-size: 24px;
        }
    }

    .QuotePopupText {
        margin-block-start: 14px;
    }

    .Field-Wrapper {
        &_type {
            &_select {
                ul {
                    li {
                        word-break: break-word;
                    }
                }
            }
        }
    }

    .Loader {
        z-index: 100;
    }

    &-QuoteAdressPopup {
        .Address_Buttons {
            display: flex;
            gap: 3%;
            padding-inline-start: 2rem;
            padding-block-start: 8rem;

            .Save_Button {
                button {
                    background-color: var(--primary-base-color);
                    color: var(--color-white);
                    padding: 1rem;
                    cursor: pointer;

                    @include mobile {
                        width: 100%;
                    }
                }
                
                @include mobile {
                    flex-basis: 50%
                }
            }

            .Cancel_Button {
                button {
                    color: var(--color-black);
                    cursor: pointer;
                    padding: 1rem;

                    @include mobile {
                        width: 100%;
                        border: 1px solid var(--primary-base-color);
                    }
                }

                @include mobile {
                    flex-basis: 50%
                }
            }

            @include mobile {
                position: fixed;
                padding: 0 1rem;
                text-align: center;
                inset-inline: 0;
                inset-block-end: 1rem;
            }
        }      
    }
}
