.Customer-page {
    max-width: 1336px;
    margin: auto;
    display: flex;
    width: 100%;

    @include mobile {
        width: unset;
        padding: 0 15px;
    }

    @include tablet {
        width: unset;
        padding: 0 14px;
    }

    .Compare_header {
        width: 100%;

        .prodcut_para {
            .product_header {
                font-weight: 700;
                line-height: 1.1;
                font-size: 1.82692308rem;
                margin-block-start: 2.16346154rem;
                margin-block-end: 1.44230769rem;
            }

            .product_para {
                font-size: 1.5rem;
                font-weight: 400;
                line-height: 1.5;
                color: #212529;
                text-align: start;
                width: 42%;
            }
        }

        @include mobile {
            display: none;
        }

        @include tablet {
            display: none;
        }
    }

    .page-head {
        .customer_header {
            font-weight: 700;
            line-height: 1.1;
            font-size: 1.82692308rem;
            margin-inline-start: 2.16346154rem;
            margin-inline-end: 1.44230769rem;

            @include mobile {
                margin-inline-start: 0;
                margin-inline-end: 0;
            }
        }
    }

    .disc {
        margin-block-end: 15px;
        margin-inline-start: 50px;

        @include mobile {
            margin-inline-start: 26px;
        }

        .disc_list {
            list-style-type: disc;
            color: #e84620;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                color: #e84620;
            }

            &::marker {
                color: black;
            }

            &::before {
                margin: 0;
            }

            &::after {
                margin: 0;
            }

            a.disc_answer {
                font-size: 1.5rem;
                font-weight: 500;
                line-height: 35px;

                &-hover {
                    text-decoration: underline;
                    color: #e84620;
                }
            }
        }
    }

    dt#answer1 {
        font-weight: 700;
        margin-block-end: 7px;
        font-size: 1.5rem;
    }

    .answer_para {
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        text-align: justify;
    }
}
