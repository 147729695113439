/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyviewQuote {
    .MyviewQuotes {
        &-Button {
            @include mobile {
                flex-basis: 33.33%;
            }

            button {
                background-color: var(--color-dark-gray);
                border: none;
                font-size: 15px;
                font-weight: 600;
                text-transform: capitalize;
                color: var(--color-black);
                opacity: 0.6;

                @include mobile {
                    font-size: 14px;
                    padding: 0;
                }

                &:hover {
                    background-color: var(--color-white);
                    color: var(--color-black);
                }
            }

            &-Selected {
                @include mobile {
                    flex-basis: 33.33%;
                }

                button {
                    background-color: var(--color-white);
                    color: var(--color-black);
                    border: none;  
                    opacity: 1;
                    font-size: 15px;
                    font-weight: 600;
                    text-transform: capitalize;

                    @include mobile {
                        font-size: 14px;
                        padding: 0;
                    }

                    &:hover {
                        background-color: var(--color-white);
                        color: var(--color-black);
                    }
                }
            }
        }

        &-ProductQuote {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .Orders {
                &-Wrapper {
                    background-color: #fafafa;
                    margin-block-start: 0;

                    .seperator:not(:last-child) {
                        flex: 1 0 auto;
                        margin-block-start: 10px;
                        height: 1px;
                        background-color: #f2f2f2;
                    }

                    .Inner {
                        padding: 12px;

                        @include mobile {
                            background-color: white;
                            border-bottom: 1px solid #f2f2f2;
                        }

                        .OrderDate,
                        .totalOrder,
                        .shipTo,
                        .status,
                        .OrderNumber {
                            display: flex;
                            justify-content: space-between;
                            margin-block-end: 12px;
                            align-items: center;

                            span {
                                color: #707070;
                                font-size: 12px;
                            }
                        }

                        .OrderNumber {
                            .seperator {
                                flex: 1 0 auto;
                                margin-inline-start: 10px;
                                height: 1px;
                                background-color: #707070;
                            }
                        }

                        .OrderStatus {
                            .status {
                                .done {
                                    font-weight: bold;
                                    color: #00741b;
                                }

                                .status_view {
                                    inset-inline-end: 1.5rem;
                                }

                                .wait {
                                    font-weight: bold;
                                    color: #bb8376;
                                }
                            }
                        }
                    }
                }
            }

            .OrderAction {
                padding: 1%;
                display: flex;
                gap: 2rem;

                .Remove_delete_icon {
                    button {
                        color: red;
                        text-decoration: underline;
                        text-underline-position: under;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .MyviewQuotes-ViewButton {
        display: flex;
        justify-content: flex-end;
        padding: 2rem 2rem 0 0;
        padding-inline-start: 0;
        box-shadow: 1px 1px 13px #00000005;
        border-radius: 3px;

        @include mobile {
            padding: 0;
        }
    }

    .MyviewQuotes-Table {
        background-color: white;
        height: auto;
        padding: 1rem 1rem 0;

        .MyviewQuotes {
            table {
                width: 100%;

                .border-bottom {
                    font-size: 16px;
                    border-bottom: 1px solid black;
                }

                .table_data {
                    font-size: 14px;

                    .Quantity_Field {
                        text-align: start;

                        .CartItem {
                            font-size: 14px;
                            height: 36px;
                            width: 6rem;
                            border: 1px solid;
                            background: none;
                            pointer-events: all;
                        }

                        .disable_quant {
                            font-size: 14px;
                            height: 36px;
                            width: 6rem;
                            border: 1px solid grey;
                            color: grey;
                            pointer-events: all;
                            cursor: not-allowed;
                            opacity: .6;
                        }
                    }

                    .Remove_Update {
                        display: flex;
                        flex-direction: column;

                        .Remove_button {
                            cursor: pointer;
                            color: red;
                            text-decoration: underline;
                            text-underline-position: under;
                        }

                        .Update_viewQuote {
                            button.Update_button {
                                color: black;
                                cursor: pointer;
                                text-decoration: underline;
                                text-underline-position: under;
                            }
                        }
                    }
                }
            }
        }
    }

    .MyviewQuotes-Myviewprices {
        padding: 2rem;
        background: white;
        line-height: 4rem;
        font-size: 16px;

        .MyQuotesExcludeTax {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .bold {
                font-weight: bold;
            }
        }

        .MyQuotesTotalPrice {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .bold {
                font-weight: bold;
            }
        }
    }

    .viewQuotecomment {
        background-color: white;
        height: auto;
        padding: 25px;

        .table_data {
            line-height: 4rem;

            .created_name {
                display: flex;
                flex-direction: revert;
                column-gap: 2rem;
                font-weight: bold;
                font-size: 16px;
            }

            .Created_text {
                font-size: 16px;
            }
        }
    }

    .viewQuotehistory {
        background-color: white;
        width: 100%;
        height: auto;
        line-height: 3rem;
        padding: 2rem;

        tr {
            display: flex;
            flex-direction: row;
            column-gap: 3rem;
            font-size: 16px;
            font-weight: bold;
        }

        .history_wrapper {
            border-bottom: 1px solid;
            margin: 15px 0;

            & > div {
                padding-block-end: 10px;
            }

            .user_info {
                span {
                    font-size: 14px;
                    font-weight: bold;
                    padding-inline-end: 5px;
                }
            }

            .status,
            .comments {
                font-size: 14px;

                h4 {
                    font-weight: bold;
                }
            }
        }

        .histoty_status {
            display: flex;
            flex-direction: column;

            .status {
                font-weight: bold;
                font-size: 16px;
            }

            .status_val {
                font-size: 14px;
            }
        }

        .text_comment {
            display: flex;
            flex-direction: column;

            .comment {
                font-size: 16px;
                font-weight: bold;
            }

            .comment_val {
                font-size: 14px;
            }
        }
    }

    .quote_name {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        margin-block-end: 2rem;
        font-size: 16px;
        margin-block-start: 2rem;
        font-weight: 700;
        align-items: center;

        .UPDATED {
            color: #bb8376;

            @include mobile {
                color: #bb8376;
            }
        }

        .SUBMITTED {
            color: #00741b;

            @include mobile {
                color: #00741b;
            }
        }

        .Created_text {
            font-size: 20px;

            @include mobile {
                font-size: 16px;
            }
        }
    }

    .comment_Form {
        h2 {
            border-bottom: 1px solid #c6c6c6;
            color: #333333;
            line-height: 2;
            font-size: 2.2rem;
            font-weight: 300;
        }

        form.Form {
            .Adding_Comments {
                .comment_text {
                    font-size: 16px;
                    line-height: 3rem;
                }

                textarea {
                    width: 100%;
                    height: 10rem;
                }
            }
        }

        .review_checkout_button {
            display: flex;
            flex-direction: row;
            gap: 2%;
            margin-block-start: 2%;

            Button {
                height: 4rem;
                text-transform: inherit;

                @include mobile {
                    width: 17rem;
                }
            }

            button.button_disabled {
                display: inline-block;
                font-weight: 600;
                padding: 7px 15px;
                font-size: 1.4rem;
                box-sizing: border-box;
                vertical-align: middle;
                height: 4rem;
                text-transform: inherit;
            }

            .Proceed_button {
                Button {
                    border: none;
                    background: var(--color-white);
                    color: var(--color-black);
                    cursor: pointer;
                    display: inline-block;
                    font-weight: 600;
                    padding: 7px 15px;
                    font-size: 1.4rem;
                    box-sizing: border-box;
                    vertical-align: middle;
                    height: 4rem;
                    text-transform: inherit;
                    white-space: nowrap;
                }

                button.button_disabled {
                    border: none;
                    background: #ffffff;
                    color: #000;
                    cursor: pointer;
                    display: inline-block;
                    font-weight: 600;
                    padding: 7px 15px;
                    font-size: 1.4rem;
                    box-sizing: border-box;
                    vertical-align: middle;
                    height: 4rem;
                    text-transform: inherit;
                }
            }
        }
    }

    .created_date {
        display: flex;
        gap: 2%;

        .created_text {
            font-size: 16px;
            font-weight: bold;
        }

        .created_date {
            font-size: 16px;
        }
    }

    .expire_date {
        margin-block-start: 1rem;

        .text_Sales {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            line-height: 2;

            @include mobile {
                justify-content: flex-start;
            }
        }

        .Expire_Date {
            .Created_text {
                display: flex;
                gap: 1rem;

                .created_text {
                    font-size: 16px;
                    font-weight: bold;
                }

                .created_date {
                    font-size: 16px;
                }
            }
        }

        .sales_rep {
            display: flex;
            gap: 1rem;

            @include mobile {
                align-items: baseline;
            }

            .created_text {
                font-size: 16px;
                font-weight: bold;
            }

            .created_date {
                font-size: 16px;
            }
        }
    }

    .close_delete {
        display: flex;
        flex-direction: row;
        gap: 2rem;

        .close_quote {
            button.close_button {
                font-size: 16px;
                color: var(--primary-base-color);
                margin-block-end: 2rem;
                margin-block-start: 2rem;
                font-weight: bold;
                text-decoration: underline;
                text-underline-position: under;
                cursor: pointer;
            }

            button.button_disabled {
                font-size: 16px;
                margin-block-end: 2rem;
                margin-block-start: 2rem;
                font-weight: bold;
                cursor: pointer;
            }
        }

        .Delete_quote {
            button.delete_button {
                font-size: 16px;
                color: var(--primary-base-color);
                margin-block-end: 2rem;
                margin-block-start: 2rem;
                font-weight: bold;
                text-decoration: underline;
                text-underline-position: under;
                cursor: pointer;
            }

            button.button_disabled {
                font-size: 16px;
                margin-block-end: 2rem;
                margin-block-start: 2rem;
                font-weight: bold;
                cursor: pointer;
            }
        }
    }

    .Shipping_AdressMethod {
        background-color: var(--color-white);
        padding: 2rem;
        margin-block-start: 2rem;

        .shipping_InfoMethod {
            h2 {
                font-size: 20px;
                margin-block-start: 0;
            }
        }

        .viewQuoteshipping {
            display: flex;
            align-items: flex-start;
            column-gap: 3rem;

            @include mobile {
                flex-direction: column;
            }

            .shipping_data {
                .shipping_InfoMethod {
                    h2 {
                        font-size: 19px;
                        white-space: nowrap;
                    }
                }

                .shipping_address {
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 37px;
                }

                .shipping_address_info {
                    font-size: 14px;
                    line-height: 20px;
                    max-width: 500px;
                    word-break: break-all;

                    .first_name {
                        padding-inline-end: 5px;
                    }
                }
            }

            .Adress_Button {
                line-height: 38px;

                .Exist_Address {
                    button {
                        color: red;
                        text-decoration: underline;
                        font-size: 14px;
                        text-underline-position: under;
                        cursor: pointer;

                        @include mobile {
                            text-align: start;
                        }
                    }
                }

                .New_Address {
                    button {
                        color: red;
                        text-decoration: underline;
                        font-size: 14px;
                        text-underline-position: under;
                        cursor: pointer;
                    }
                }
            }
        }

        .shipping_data {
            flex: 1;

            .Shipping_Payment_val {
                line-height: 35px;

                .shipping_InfoMethod {
                    font-size: 16px;
                    font-weight: bold;
                }

                .shipping_val {
                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .Orders {
        &-Wrapper {
            .seperator:not(:last-child) {
                flex: 1 0 auto;
                margin-block-start: 10px;
                height: 1px;
                background-color: #f2f2f2;
            }

            .Inner {
                padding: 12px;

                @include mobile {
                    background-color: white;
                    border-bottom: 1px solid #f2f2f2;
                }

                .OrderDate,
                .totalOrder,
                .shipTo,
                .status,
                .OrderNumber {
                    display: flex;
                    justify-content: space-between;
                    margin-block-end: 12px;
                    align-items: center;

                    span {
                        color: #707070;
                        font-size: 12px;
                    }

                    .bold {
                        font-weight: bold;
                        color: #000;
                        margin-inline-start: 12px;

                        .CartItem {
                            pointer-events: all;
                            border: 1px solid;
                            font-size: 12px;
                        }

                        .disable_quant {
                            border: 1px solid grey;
                            opacity: 0.6;
                            font-size: 12px;
                        }

                        .Field-Wrapper {
                            .Field {
                                margin-block-start: 14px;
                                display: flex;
                                justify-content: flex-end;

                                input {
                                    width: 25px;
                                    pointer-events: all;
                                    border: 1px solid;

                                    @include mobile {
                                        width: 25px;
                                    }
                                }
                            }
                        }
                    }
                }

                .OrderNumber {
                    .seperator {
                        flex: 1 0 auto;
                        margin-inline-start: 10px;
                        height: 1px;
                        background-color: #707070;
                    }
                }

                .OrderStatus {
                    .status {
                        .done {
                            font-weight: bold;
                            color: #00741b;
                        }

                        .status_view {
                            inset-inline-end: 1.5rem;
                        }

                        .wait {
                            font-weight: bold;
                            color: #bb8376;
                        }
                    }
                }

                .OrderAction {
                    display: flex;
                    justify-content: flex-end;
                    gap: 10px;
                    align-items: center;

                    .quote_button {
                        color: var(--primary-base-color);
                        text-decoration: underline;
                        text-underline-position: under;
                        cursor: pointer;
                        font-size: 12px;
                    }

                    .Vline {
                        height: 12px;
                        width: 1px;
                        background-color: #9d9d9d;
                    }
                }
            }
        }
    }
}
