/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import 'abstract/variables';
@import 'abstract/media';
@import 'abstract/button';
@import 'abstract/loader';
@import 'abstract/icons';
@import 'abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import './abstract/abstract';
@import './base/breakpoints';
@import './base/keyframes';
@import './base/root';
@import './base/reset';
@import './base/button';
@import './base/list';
@import './base/link';
@import './base/table';
@import './base/input';
@import './base/fieldset';
@import './base/blockquote';
@import './base/font';
@import './cms/block';
@import './cms/index';

li::before {
    display: none;
}

.customImg {
    width: auto;
}

[class^= 'LocalizationWrapper'] {
    /* stylelint-disable-next-line declaration-no-important */
    overflow: unset !important;
}
