/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyQuotes {
    &-Table {
        .MyQuotesTable {
            padding: 22px 23px;

            .Quotes_table {
                width: 100%;
                border-bottom: 1px solid black;

                .border-bottom {
                    border-bottom: 1px solid black;
                    font-size: 16px;
                }

                .table_data {
                    font-size: 14px;

                    .quote_button {
                        color: var(--primary-base-color);
                        text-decoration: underline;
                        text-underline-position: under;
                        cursor: pointer;
                    }

                    .UPDATED {
                        color: #BB8376;

                        @include mobile {
                            color: #BB8376;
                        }
                    }
                    
                    .SUBMITTED {
                        color: #00741B;

                        @include mobile {
                            color: #00741B; 
                        }
                    }
                }  
            }
        }
    }

    &-SearchOrderID {
        width: 100%;
        height: 123px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 1px 1px 13px #00000005;
        border-radius: 3px;
        opacity: 1;
        padding-inline-start: 3%;

        @include mobile {
            height: 17rem;
        }

        .MyQuotesHeader {
            .QuotesHeader {
                text-align: start;
                font-size: 16px;
                letter-spacing: 0px;
                color: #0F0F0F;
                opacity: 1;
                text-transform: inherit;
                padding-block-start: 1rem;
            }
        }

        .SearchOrder {
            display: flex;
            gap: 2%;

            @include mobile {
                flex-direction: column;
                display: flex;
                row-gap: 1rem;
            }

            @include tablet {
                display: flex;
            }

            .MyQuotesSearch {
                .mySearch {
                    width: 500px;
                    height: 40px;
                    background: #FAFAFA 0% 0% no-repeat padding-box;
                    box-shadow: inset 1px 1px 6px #00000014;
                    border-radius: 4px;
                    opacity: 1;
                    font-size: 14px;

                    @include mobile {
                        width: 96%;
                        height: 36px;
                    }

                    @include tablet {
                        width: 100%;
                    }
                }
            }

            .MyQuotesButton {
                @include mobile {
                    display: flex;
                    justify-content: flex-end;
                }

                @include tablet {
                    display: flex;
                    justify-content: flex-end;
                }

                .SearchButton {
                    width: 178px;
                    height: 40px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 1px solid #E53D2F;
                    border-radius: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #E53D2F;
                    text-transform: capitalize;
                    opacity: 1;
                    font-size: 14px;
                    letter-spacing: 0px;
                    font-weight: bold;

                    &:hover {
                        cursor: pointer;
                        background-color: #E53D2F;
                        color: #FFFFFF;
                    }

                    @include mobile {
                        width: 113px;
                        height: 36px;
                        background: var(--primary-base-color) 0% 0% no-repeat padding-box;
                        border-radius: 2px;
                        opacity: 1;
                        color: #FFFFFF;
                        font-size: 12px;
                        inset-inline-end: 1.5rem;

                        &:hover {
                            cursor: pointer;
                            background-color: #FFFFFF;
                            color: var(--primary-base-color);
                        }
                    }
                }
            }
        }
    }

    .quotestable {
        height: auto;
        box-shadow: 1px 1px 13px #00000005;
        border-radius: 3px;
    }

    .Orders {
        &-Wrapper {
            background-color: #fafafa;
            margin-block-start: 12px;
    
            .seperator:not(:last-child) {
                flex: 1 0 auto;
                margin-block-start: 10px;
                height: 1px;
                background-color: #F2F2F2;
            }
    
            .Inner {
                padding: 12px;
    
                @include mobile {
                    background-color: white;
                    border-bottom: 1px solid #F2F2F2;
                }
    
                .OrderDate,
                .totalOrder,
                .shipTo,
                .status,
                .OrderNumber {
                    display: flex;
                    justify-content: space-between;
                    margin-block-end: 12px;
                    align-items: center;
    
                    span {
                        color: #707070;
                        font-size: 12px;
                    }
    
                    .bold {
                        font-weight: bold;
                        color: #000;
                        margin-inline-start: 12px;
                    }
                }
    
                .OrderNumber {
                    .seperator {
                        flex: 1 0 auto;
                        margin-inline-start: 10px;
                        height: 1px;
                        background-color: #707070;
                    }
                }
    
                .OrderStatus {
                    .status {
                        .done {
                            font-weight: bold;
                            color: #00741b;
                        }
    
                        .status_view {
                            inset-inline-end: 1.5rem;
                        }
    
                        .wait {
                            font-weight: bold;
                            color: #bb8376;
                        }
                    }
                }
    
                .OrderAction {
                    display: flex;
                    justify-content: flex-end;
                    gap: 10px;
                    align-items: center;
    
                    .quote_button {
                        color: var(--primary-base-color);
                        text-decoration: underline;
                        text-underline-position: under;
                        cursor: pointer;
                        font-size: 12px;
                    }
    
                    .Vline {
                        height: 12px;
                        width: 1px;
                        background-color: #9D9D9D;
                    }
                }
            }
        }
    }

    .quotepaginationtable {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 1px 1px 13px #00000005;
        border-radius: 3px;
        margin: 2rem 0;

        .order_pagination {
            .orderd_list_item {
                inset-block-end: 4rem;
                inset-inline-start: 2rem;
                display: flex;
                gap: 1rem;
                font-size: 16px;
                color: #9D9D9D;

                p {
                    color: #9D9D9D;
                }
            }
        }
    }

    &-NoOrders {
        p {
            padding: 10px;
            font-size: 16px;
        }
    }
}
