.Price-changes {
    @include mobile {
        padding: 20px;
    }

    h1 {
        font-weight: 700;
        line-height: 1.1;
        font-size: 2.59615385rem;
        margin-block: 0 2.88461538rem;
        text-align: start;
    }
    
    h2 {
        font-weight: 700;
        line-height: 1.1;
        font-size: 2.59615385rem;
        margin-block: 0 2.88461538rem;
        text-align: start;
    }
    
    a {
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
    }

    p {
        font-size: 15px;
        line-height: 22px;
    }
}

