/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.FooterPopup {
    position: fixed;
    background: #4f4f4f82;
    z-index: 900;
    inset-block-start: 0;
    inset-inline-end: 0;
    display: flex;
    inset-block-end: 0;
    inset-inline-start: 0;
    align-items: center;
    justify-content: center;

    .popup {
        display: flex;
        background: #fff;
        flex-direction: column;
        border-radius: 16px;
        max-width: 490px;
        width: 100%;
        align-items: center;
        justify-content: center;

        @include mobile {
            margin-inline: 2rem;
        }
    }

    .Popup-CloseBtn {
        inset-inline-end: 0%;
        inset-block-start: 0;
        position: absolute;
        cursor: pointer;
        z-index: 99;

        svg {
            width: 38px;
            height: 38px;
            fill: var(--color-white);
        }
    }
}
