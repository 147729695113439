/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.PartnerWithUs {
    margin: 0 auto;

    .partnerUsWrapper {
        height: 650px;
        display: flex;
        justify-content: center;
    }

    .top-content {
        width: 100%;
        max-width: 1320px;
        position: absolute;
        inset-block-start: 0;
        margin: 239px auto 0 auto;
        height: 580px;

        &-wrapper {
            margin: 0 auto;
            width: 100%;
            height: 819px;
            position: absolute;
            inset-block-start: 0;
            display: flex;
            justify-content: center;
        }

        @include desktop {
            display: flex;
        }

        &-container-one-first {
            @include desktop {
                padding: 0 0 0 13%;
            }
        }

        &-container-one {
            @include desktop {
                width: 65%;
                z-index: 3;
                display: flex;
                flex-direction: column;
                max-height: 579px;
                height: 100%;
                justify-content: space-between;
                padding: 0 23px 0 0;
            }
        }

        &-container-two {
            @include desktop {
                width: 37%;
                z-index: 3;
            }

            @include tablet {
                margin-block-start: auto;
            }
        }

        &-white-background {
            width: 100%;
            max-width: 1320px;
            background: white;
            z-index: 1;
            position: absolute;
            height: 650px;
        }

        &-background {
            width: 100%;
            height: 650px;
            background-image: url("src/util/Images/Backgroundleaf.png");
            background-repeat: no-repeat;
            background-position-x: right;
            background-position-y: bottom;
            background-size: contain;
            z-index: 2;
            position: absolute;
            max-width: 1440px;
        }

        .PartnerUs {
            &-Heading {
                max-width: 200px;
                height: 80px;
                text-align: start;

                .Heading {
                    &-text {
                        font-size: 30px;
                        font-family: $font-Montserrat;
                        color: #000;
                        font-weight: bold;
                    }
                }
            }

            &-desc {
                display: flex;
                flex-direction: column;
                column-gap: 3%;
                font-size: 16px;
                font-family: "Lato", sans-serif;
                margin-block-start: 2.4%;
                margin-block-end: 3.6%;
                max-width: 565px;
                width: 100%;
                padding: 0 2px 0 0;

                .para {
                    font-size: 16px;
                }
            }
        }

        .openAccTgr {
            .openAcc {
                &-btn {
                    width: 257px;
                    height: 40px;
                    background-color: var(--primary-base-color);
                    color: #fff;
                    border-radius: 6px;
                    font-size: 14px;
                    font-weight: bold;
                    font-family: "Lato", sans-serif;
                    padding: 0;

                    &:hover {
                        outline: var(--primary-base-color) solid 1px;
                        color: var(--primary-base-color);
                        background: #fff;
                        cursor: pointer;
                    }

                    @include mobile {
                        width: 240px;
                        height: 36px;
                    }
                }
            }
        }

        .partnerUsimg {
            max-width: 413px;
            width: 100%;

            .img-cnt {
                width: 100%;
                z-index: 1;
                object-fit: contain;

                img {
                    max-width: 413px;
                    max-height: 579px;
                    width: 100%;
                    height: 100%;
                }
            }

            @include mobile {
                max-width: 315px;
                width: 100%;
                height: 336px;
                margin: auto;
            }
        }

        &-container-one-second {
            @include desktop {
                max-height: 145px;
                height: 100%;
            }
        }

        &-conatiner-three {
            max-width: 1440px;
            max-height: 145px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            grid-column-gap: 22px;
            align-items: center;

            .card {
                max-width: 255px;
                max-height: 145px;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                border-radius: 6px;
                align-items: center;
                background: 1px;
                border: 1px solid #ffffff6f;
                backdrop-filter: blur(8px);
                -webkit-backdrop-filter: blur(8px);

                .card {
                    &-icon-holder {
                        min-width: 41px;
                        padding-inline-start: 5%;

                        .icon-img {
                            object-fit: contain;
                            width: fit-content;
                        }
                    }

                    &-card-txt {
                        font-size: 16px;
                        font-weight: bold;
                        width: 60%;
                        padding: 0 5%;
                        color: #fff;
                    }
                }
            }
        }
    }

    .cards-us {
        background-image: url("src/util/Images/B1.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: 439px;
        width: 100%;

        @include desktop {
            margin: -211px 0 0 0;
        }
    }
}

@include small-mobile() {
    .PartnerWithUs.onlymobile {
        .top-content {
            min-width: unset;
        }
    }
}

@include mobile() {
    .PartnerWithUs {
        margin: 0 auto;
        margin-block-start: 30px;
        overflow: hidden;

        .partnerUsWrapper {
            inset-block-start: 0;
            inset-inline-start: 63px;
            width: 434px;
            height: 433px;
            position: absolute;
            transform: matrix(-0.91, -0.42, 0.42, -0.91, 62, 89) rotate(223deg);
            background-image: url("src/util/Images/Backgroundleaf.png");
            background-repeat: no-repeat;
            background-position-x: right;
            background-position-y: bottom;
            background-size: contain;
        }

        .top-content {
            height: unset;
            margin: 0 auto;
            padding: 0 30px;
            min-width: 315px;
            position: relative;

            .PartnerUs {
                &-Heading {
                    max-width: unset;
                    width: unset;
                    height: unset;
                    margin-block-end: 16px;
                    padding: 0 30% 0 0;

                    .Heading {
                        &-text {
                            font-size: 20px;
                            font-family: "Montserrat", sans-serif;
                            color: #000;
                            font-weight: bold;
                        }
                    }
                }

                &-desc {
                    display: flex;
                    flex-direction: column;
                    column-gap: 3%;
                    font-size: 14px;
                    font-family: "Lato", sans-serif;
                    margin-block-end: 24px;
                    width: unset;

                    .para {
                        font-size: 14px;
                    }
                }
            }

            .openAccTgr {
                margin: 0 0 39px 0;
                max-width: 240px;
                width: 100%;
            }
        }

        .cards-us {
            background-image: url("src/util/Images/B1.png");
            background-repeat: no-repeat;
            background-size: cover;
            min-height: 477px;
            position: absolute;
            inset-block-end: 0;
        }

        .cards-wrapper {
            z-index: 3;
            width: 100%;
            display: flex;
            flex-direction: column;
            grid-row-gap: 16px;
            align-items: center;
            padding-block-start: 16px;
            padding-block-end: 24px;

            .card {
                max-width: 315px;
                width: 100%;
                height: 88px;
                display: flex;
                justify-content: center;
                border-radius: 6px;
                align-items: center;
                background: 1px;
                border: 1px solid #ffffff6f;
                backdrop-filter: blur(8px);
                -webkit-backdrop-filter: blur(8px);

                .card {
                    &-icon-holder {
                        max-height: 48px;
                        max-width: 48px;
                        width: 100%;

                        .icon-img {
                            object-fit: contain;
                        }
                    }

                    &-card-txt {
                        font-size: 16px;
                        font-weight: bold;
                        color: #fff;
                    }
                }

                @include mobile {
                    gap: 30px;
                }
            }
        }
    }
}

.PartnerWithUs.onlydesktop {
    @include mobile {
        display: none;
    }
}

.PartnerWithUs.onlymobile {
    @include desktop {
        display: none;
    }
}
