/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CarouselScrollArrow {
    $arrow-size: 25px;

    z-index: 1;
    transition: opacity 200ms;
    cursor: pointer;
    transform: rotate(-180deg);
    width: $arrow-size;
    height: $arrow-size;

    .ChevronIcon {
        width: 100%;
        height: 100%;
    }

    &:hover {
        transform: rotate(-180deg) scale(1.2);
    }

    &_isNextArrow {
        transform: none;
        display: none;

        &:hover {
            transform: scale(1.2);
        }
    }

    &_isInvisible {
        visibility: hidden;
    }
}
