.Shipping_info {
    width: 100%;
    max-width: 1325px;
    margin: auto;

    @include mobile {
        width: unset;
    }

    @include tablet {
        width: unset;
    }

    .shipping_header {
        .info_header {
            font-weight: 700;
            line-height: 1.1;
            font-size: 2.59615385rem;
            margin-block-start: 0;
            margin-block-end: 2.88461538rem;

            @include mobile {
                text-align: start;
            }

            @include tablet {
                text-align: start;
            }
        }
    }

    .shipping_list_header {
        display: flex;

        .Compare_header {
            width: 40%;
            justify-content: center;
            display: flex;

            .prodcut_para {
                .product_header {
                    font-weight: 700;
                    line-height: 1.1;
                    font-size: 1.82692308rem;
                    margin-block-start: 2.16346154rem;
                    margin-block-end: 1.44230769rem;
                }

                .product_para {
                    font-size: 1.5rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #212529;
                    width: 62%;
                }
            }

            @include mobile {
                display: none;
            }

            @include tablet {
                display: none;
            }
        }
    }

    .shipping_list {
        padding-block-start: 27px;

        .shipping_main_list {
            margin-block-start: 0;
            margin-block-end: 3.60576923rem;

            .shipping_list_para {
                margin-block-start: 0;
                margin-block-end: 1.44230769rem;
                font-size: 1.5rem;
                font-weight: 700;
                line-height: 1.5;
                color: #212529;
                text-align: justify;
            }

            .shipping_main_header {
                font-weight: 700;
                line-height: 1.1;
                font-size: 2.59615385rem;
                margin-block-start: 0;
                margin-block-end: 2.88461538rem;
                text-transform: none;

                @include mobile {
                    text-align: start;
                }

                @include tablet {
                    text-align: start;
                }
            }

            .shipping_sub_list {
                margin-block-start: 0;
                margin-block-end: 1.44230769rem;
                font-size: 1.5rem;
                font-weight: 700;
                list-style-type: disc;
                line-height: 1.5;

                &::before {
                    position: fixed;
                }
    
                &::after {
                    position: fixed;
                }

                .shipping_mail_list {
                    margin-block-start: 0;
                    margin-block-end: 1.44230769rem;
                    font-size: 1.5rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #212529;
                    list-style-type: disc;

                    &::before {
                        position: fixed;
                    }
        
                    &::after {
                        position: fixed;
                    }
                }
            }

            .info_main_header {
                font-weight: 700;
                line-height: 1.1;
                font-size: 1.82692308rem;
                margin-block-start: 2.16346154rem;
                margin-block-end: 1.44230769rem;
            }

            .shipping_Royal_list {
                margin-block-start: 0;
                margin-block-end: 1.44230769rem;
                font-size: 1.5rem;
                font-weight: 400;
                list-style-type: disc;
                line-height: 1.5;

                &::before {
                    position: fixed;
                }
    
                &::after {
                    position: fixed;
                }
            }

            .shipping_mail_list {
                margin-block-start: 0;
                margin-block-end: 1.44230769rem;
                font-size: 1.5rem;
                font-weight: 400;
                line-height: 1.5;
                color: #212529;
                list-style-type: circle;

                &::before {
                    position: fixed;
                }
    
                &::after {
                    position: fixed;
                }
            }
        }

        .shipping_para {
            margin-block-start: 0;
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            margin-block-end: 1.44230769rem;
            text-align: justify;
        }
    }
}
