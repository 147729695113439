.payment_info {
    width: 100%;
    max-width: 1325px;
    margin: auto;

    @include mobile {
        width: unset;
        padding: 0 14px;
    }

    @include tablet {
        width: unset;
        padding: 0 14px;
    }

    .payment_header {
        .info_header {
            font-weight: 700;
            line-height: 1.1;
            font-size: 2.59615385rem;
            margin-block-start: 0;
            margin-block-end: 0.88461538rem;
        }
    }

    .payment_list_header {
        display: flex;

        .Compare_header {
            width: 25%;
            justify-content: center;
            display: flex;

            .prodcut_para {
                margin-inline-end: 1rem;

                .product_header {
                    font-weight: 700;
                    line-height: 1.1;
                    font-size: 1.82692308rem;
                    margin-block-start: 2.16346154rem;
                    margin-block-end: 1.44230769rem;
                }

                .product_para {
                    font-size: 1.5rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #212529;
                    width: 81%;
                }
            }

            @include mobile {
                display: none;
            }

            @include tablet {
                display: none;
            }
        }

        .payment_list {
            .payment_main_header {
                font-weight: 700;
                line-height: 1.1;
                font-size: 1.826923rem;
                margin-block-start: 2.16346154rem;
                margin-block-end: 1.44230769rem;
                text-transform: none;
            }

            .payment_note {
                margin-block-start: 0;
                margin-block-end: 1.44230769rem;
                font-size: 1.5rem;
                font-weight: 400;
                line-height: 1.5;
                color: #212529;
                text-align: start;
            }

            .payment_main_list {
                margin-block-start: 0;

                @include desktop {
                    margin-block-end: 3.60576923rem;
                }

                margin-block-end: 0.884615rem;
                font-size: 1.5rem;
                font-weight: 400;
                line-height: 1.5;
                color: #212529;
            }

            .line_space {
                margin-block-end: 9rem;
            }

            .payment_note_para {
                margin-block-start: 0;
                margin-block-end: 1.44230769rem;
                font-size: 1.5rem;
                font-weight: 400;
                line-height: 1.5;
                color: #212529;
                text-align: justify;
            }
        }
    }
}
