/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/parts';

/**
 * Amasty Extra Fee compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.Fee {
    &-Name {
        margin-block-start: 16px;
        font-size: 16px;
        font-weight: 600;

        &_Mandatory::after {
            content: "*";
            display: inline-block;
            color: var(--primary-error-color, red);
        }
    }

    &-Description {
        margin-block-start: 8px;
        display: none;
    }

    .Field {
        margin-block-start: 0;
    }

    label {
        font-size: 16px;

        @include mobile {
            font-size: 14px;
        }
    }
}
