/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.RangeSelector {
    visibility: visible;

    &-Wrapper {
        padding: 0 1.5rem;
        padding-block-start: 1rem;

        .input-range__label-container {
            display: none;
        }

        .input-range__track--active {
            background-color: var(--primary-base-color);
        }

        .input-range__slider {
            background-color: #fff;
            border-color: #fff;
            box-shadow: 0px 2px 6px #00000029;
            margin-block-start: -1rem;
            width: 2rem;
            height: 2rem;
        }
    }

    &-DetailsWrapper {
        padding: 1rem 0;
        display: flex;
        align-items: center;
        gap: 2rem;
        margin-block-start: 1rem;
    }

    &-PriceDivider {
        font-size: 12px;
        font-weight: 500;
    }

    &-PriceItem {
        display: inline-block;
        width: 50%;
        border: 1px solid #9f9b9b;
        text-align: center;
        padding: 0.5rem;
        font-size: 1.2rem;
        color: #9f9b9b;
    }
}
