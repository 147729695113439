/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CategoryListWidgetBreeders {
    .CategoryFilterOverlay-Wrapper {
        @include  mobile {
            grid-template-rows: min-content min-content min-content;
            gap: 1rem;
        }
    }

    .CategoryFilterOverlay-MobileWrapper {
        column-count: 2;
    }

    &-SortField {
        .Field {
            &-Wrapper_type_select {
                .Field_type_select {
                    margin: 0px;
                }
            }
        }
    }

    &-breedersFilterData {
        display: flex;
        column-gap: 20px;
        align-items: baseline;
    }

    &-Span-Content {
        font-size: 12px;
        font-weight: bold;
        text-transform: capitalize;
    }

    @include desktop {
        display: grid;
        grid-template-columns: 2fr 7fr;
        margin-block: 31px 25px;
    }

    font-size: 14px;
    color: #000000;
    font-weight: 500;

    @include mobile {
        .Overlay_isVisible {
            z-index: 9999;
            height: 100%;
        }
    }

    &-Checkbox {
        label {
            font-size: 14px;
            font-weight: bold;

            // .input {
            //     &-control {
            //         margin-inline-end: 16px;
            //         border: 1px solid #bbbbbb;
            //         border-radius: 1px;
            //         opacity: 1;
            //     }
            // }
        }
    }

    &-LayoutWrapperFilter {
        height: 36px;
        width: 48%;
        border: 1px solid #000000;

        @include desktop {
            display: grid;
        }

        font-size: 12px;
        font-weight: bold;
    }

    @include mobile {
        &-LayoutWrapperFilter {
            height: 36px;
            width: 48%;
            border: 1px solid #000000;
        }

        .CategoryPage-Filter {
            width: 100%;
            height: 100%;
            font-size: 12px;
            font-weight: bold;
            justify-content: center;
            text-transform: uppercase;

            span {
                font-size: 12px;
                font-weight: bold;
                text-transform: capitalize;
            }
        }
    }

    .TextPlaceholder,
    .Image_isPlaceholder {
        opacity: 0;
        animation: appear 100ms 300ms forwards;
    }

    .CategorySortOverlay {
        &_isVisible {
            @include mobile {
                inset-block-start: 0;
            }
        }
        
        &-SortHeader {
            display: flex;
            justify-content: space-between;
            padding: 3rem 16px;
            align-items: center;
            z-index: 1;
            border-bottom: 1px solid var(--primary-divider-color);

            .action-icons {
                display: flex;
                align-items: flex-start;
                grid-column-gap: 23px;
                column-gap: 23px;
            }
        }

        &-SeeResults {
            position: fixed;
            inset-block-end: 0;
            background-color: var(--color-white);
            border-top: 1px solid var(--primary-divider-color);
            width: 100%;
            padding: 16px;
        }
    }

    &-Filters {
        border-bottom: 1px solid #ebebeb;
        padding-block-end: 25px;

        @include mobile {
            display: none;
        }
    }


    &-RenderNoCategories {
        font-size: 20px;
        color: #9b9b9b;
    }

    &-totalResult {
        color: #9b9b9b;
        font-size: 16px;

        @include desktop {
            margin-inline-start: 30px;
        }

        @include mobile {
            margin: auto;
        }
    }

    &-HeadingContent {
        @include mobile {
            display: grid;
            grid-template-columns: 2fr 2fr;
        }

        @include desktop {
            display: flex;
            padding-block-end: 12px;
            border-bottom: 1px solid #ebebeb;
        }
    }

    &-Sort {
        margin-inline-start: auto;
        display: inline-flex;
        gap: 40px;
        font-size: 16px;
        font-weight: bold;

        select {
            border-bottom: unset;
            padding-inline-end: 20px;
            height: 30px;
            padding: 5px 20px;
            border: none;
            background-color: #FAFAFA;
        }

        .ChevronIcon {
            display: inline-block;
            inset-inline-end: 5px;
        }

        .FieldSelect-Options {
            inset-inline: 0 0;
            inset-inline-start: auto;
            width: 160px;
            border: unset;
            box-shadow: 0px 3px 11px #0000001c;
        }
    }

    &-SortBy {
        @include mobile {
            width: 48%;
            height: 36px;
            font-size: 14px;
            border: 1px solid #000000;
        }

        span {
            font-size: 12px;
            font-weight: bold;
            text-transform: capitalize;
        }
    }

    &-category {
        @include desktop {
            margin-inline-start: 25px;
        }
    }

    &-Miscellaneous {
        z-index: 1;
        display: flex;
        column-gap: 10px;
        align-items: center;
        margin-block-end: 30px;

        @include desktop {
            padding-block-start: 0;
            padding-block-end: 10px;
            padding-inline: 0;
            transform: translateY(0);
            transition: transform 200ms cubic-bezier(0.47, 0, 0.745, 0.715);
        }

        @include mobile {
            inset-block-end: 0;
            inset-inline-start: 0;
            width: 100%;
            z-index: 90;
            grid-template-columns: repeat(2, 1fr);
            height: auto;
            flex-wrap: wrap;
            padding: 0;

            &-Left {
                min-width: 100%;

                .CategoryPage-ItemsCount {
                    margin: 0;
                    padding: 0;
                }
            }

            .CategoryPage-MiscellaneousLayoutWrapper {
                height: 36px;
                width: 50%;
                border: 1px solid #000000;
                justify-content: center;

                .CategoryPage-SortLabel {
                    display: none;
                }

                .FieldSelect-SortSelect {
                    border: none;
                }
            }

            &_noResults {
                height: auto;
            }
        }

        @include tablet {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
        }

        @include tablet-portrait {
            grid-template-rows: 1fr 1fr;
        }

        .hideOnScroll & {
            @include mobile {
                // transform: translateY(-100%);
            }
        }

        > * {
            @include mobile {
                padding-inline-start: 14px;
                padding-inline-end: 14px;
                margin: auto;
            }
        }

        &-Left {
            display: flex;
            align-items: center;
            gap: 1.5rem;

            @include mobile {
                margin-block-end: 1rem;
            }
        }
    }

    &-Filter {
        width: 100%;
        height: 100%;
        font-size: 14px;
        justify-content: center;
        text-transform: uppercase;

        @include desktop {
            display: none;
        }
    }

    &-FilterHeading {
        margin-block-end: 20px;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-Title {
        margin-block-start: 16px;
        font-size: 14px;
        color: #707070;

        @include mobile {
            margin-inline-start: 16px;
        }
    }

    &-ResetButton {
        .ResetButton-Button {
            width: 100%;
            border: none;
            font-size: 12px;
            color: var(--primary-base-color);
            height: 15px;
            text-transform: capitalize;

            &:hover {
                height: 15px;
            }
        }
    }

    &-HeadingBorder {
        border-bottom: 1px solid #ebebeb;
    }

    &-SubCategory {
        display: grid;

        @include desktop {
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 30px;
        }

        @include mobile {
            grid-template-columns: 1fr 1fr;
            grid-gap: 5px;
        }

        margin-block-start: 35px;
    }

    .Field {
        @include mobile {
            display: inline-block;
        }
    }

    .Field [type='radio']:hover:not(:disabled) + .input-control {
        @include desktop {
            background-color: unset;
            border-color: unset;
        }
    }

    .Field [type="radio"] + .input-control {
        margin-inline-end: 16px;
        border: 1px solid #bbbbbb;
        border-radius: unset;
        opacity: 1;
    }

    .Field [type="radio"]:checked + .input-control {
        border: 1px solid var(--primary-base-color);
    }

    .CategoryListWidget {
        &-categoryTitle {
            font-size: 16px;
            font-weight: bold;

            @include  mobile {
                margin-block-start: 0;
            }
        }

        &-breedersFilter {
            column-count: 2;
        }

        &-subCategoryItemContent {
            display: grid;
            align-items: center;
            justify-items: center;
            padding: 25px;
            box-shadow: 4px 9px 39px #0000000D;
            min-height: 251px;

            @include mobile {
                padding: 20px;
            }

            .ProductReviewRating {
                display: none;
            }
        }
    }

    .CategoryListWidget-subCategoryItemTitle {
        text-align: center;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        @include desktop {
            margin-block-end: 27px;
            margin-block-start: 27px;
        }

        @include mobile {
            max-height: 32px;
        }
    }

    .CategoryListWidget-ShopNow {
        background-color: var(--primary-base-color);
        height: 40px;
        width: 56%;
        text-transform: capitalize;

        @include mobile {
            width: 90%;
        }

        &:hover {
            background-color: var(--primary-base-color);
            height: 40px;
            width: 56%;

            @include mobile {
                width: 90%;
            }

            padding: unset;
            border: 1px solid var(--primary-base-color);
        }
    }

    .CategoryListWidget-ShopNowButtonText {
        white-space: nowrap;

        @include mobile {
            font-size: 12px;
        }
    }

    .CategoryListWidget-subCategoryItemIcon {
        margin-block-start: 15px;
        width: 80px;
        height: 80px;
        margin: auto;
    }
}
