/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.QuickOrderCsvUploader {
    &-Select_files {
        display: flex;
        flex-direction: column;
        column-gap: 50px;
        padding-block: 15px;
        border-bottom: 1px solid #9d9d9d;

        @include mobile() {
            flex-direction: column;
            gap: 1rem;
        }

        .Form {
            display: flex;
            flex-direction: row;
            gap: 5rem;

            @include mobile {
                flex-direction: column;
            }

            .Button {
                background-color: #fff;
                color: var(--primary-base-color);
                padding-inline: 40px;
                height: 42px;

                @include mobile {
                    width: 100%;
                    padding-inline: 0;
                }

                &:not([disabled]):hover {
                    border-color: var(--primary-base-color);
                    padding-inline: 40px;
                }
            }
        }

        .Field-Wrapper {
            @include desktop {
                width: 45%;
            }

            &_type_file {
                .Field {
                    margin-block-start: 0;
                }

                .Field-LabelContainer {
                    label {
                        &.Field-Label {
                            font-weight: 600;
                            pointer-events: none;
                            margin-block-start: -20px;
                        }
                    }
                }

                .FieldFile-AllowedTypes {
                    display: none;
                }

                .Field-ErrorMessages {
                    padding-block-start: 20px;
                }

                input {
                    width: 100%;
                    background-color: #f5f5f5;
                    border-style: none;
                    border-radius: 3px;
                    height: 0px;
                    pointer-events: none;

                    + label {
                        align-items: center;
                        border: 1px solid var(--input-border-color);
                        background-color: #f5f5f5;
                        cursor: pointer;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        height: 45px;
                        width: 100%;
                        box-shadow: inset 1px 1px 6px #00000014;

                        &.success {
                            border: 1px solid var(--primary-success-color);
                        }

                        span {
                            color: #fff;
                            background: #BB8376;
                            text-align: center;
                            font-size: 14px;
                            width: auto;
                            font-weight: 500;
                            padding: 9px 6px;
                            margin-inline-end: 16px;
                            border-radius: 4px;
                            text-transform: capitalize;
                        }

                        p {
                            color: #9d9d9d;
                            margin-inline-start: 16px;
                            font-size: 14px;
                        }

                        .successTick {
                            margin-inline-end: 12px;
                        }
                    }
                }
            }
        }
    }

    &-sampleFileDownload {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-block-start: 15px;

        p {
            margin-block-end: 0;
        }

        .QuickOrderCsvUploader-sampleFileDownloadButton {
            color: var(--primary-base-color);
            border-bottom: 1px solid var(--primary-base-color);
            cursor: pointer;
        }
    }

    &-NoteDescription {
        p {
            margin-block-end: 5px;
            color: var(--primary-base-color);
        }
    }
}
