/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable rule-empty-line-before */
/* stylelint-disable indentation */
.ContactForm {
    .Field-Wrapper {
        background-clip: padding-box;
        border-radius: 0;
        font-size: 15px;
        height: auto;
        line-height: 35px;
        padding: 0 9px;
        vertical-align: baseline;
        box-sizing: border-box;
    }

    textarea {
        border-radius: 0;
        font-size: 15px;
        line-height: 12px;
        margin: 0;
        padding: 15px;
        vertical-align: baseline;
        width: 100%;
        box-sizing: border-box;
        resize: vertical;
        height: 145px;
        background: #fff;
    }

    .Field-Wrapper:first-of-type .Field {
        margin-block-start: 0;
        border: 1px solid #b0b0b0;
        background: #fff;
        width: 50%;

        input:focus {
            border: none !important; /* stylelint-disable-line declaration-no-important */
        }

        @include mobile {
            width: 100%;
        }

        @include tablet {
            width: 100%;
        }

        input {
            width: 100%;
            background-color: #fff;
        }
    }

    .Field.Field_type_email {
        border: 1px solid #b0b0b0;
        width: 50%;
        background: #fff;

        input:focus {
            border: none !important; /* stylelint-disable-line declaration-no-important */
        }

        @include mobile {
            width: 100%;
        }

        @include tablet {
            width: 100%;
        }
        
            input {
                width: 100%;
                background-color: #fff;
            }
    }

    .Field.Field_type_text {
        background: #fff;
        border: 1px solid #b0b0b0;
        width: 50%;

        input:focus {
            border: none !important; /* stylelint-disable-line declaration-no-important */
        }

        @include mobile {
            width: 100%;
        }

        @include tablet {
            width: 100%;
        }

        input {
                width: 100%;
                background-color: #fff;
            }
    }

    .Field.Field_type_textarea {
        border: 1px solid #b0b0b0;
        background: #fff;
        width: 50%;
        height: auto;
        line-height: 12px;

        @include mobile {
            width: 100%;
        }

        @include tablet {
            width: 100%;
        }
    }
    
    .Button {
        background: 0 0;
        cursor: pointer;
        display: inline-block;
        font-weight: 400;
        color: #e84620;
        padding: 9px 35px;
        font-size: 1.15384615rem;
        border-radius: 30px;
        line-height: 1.92307692rem;
        box-sizing: border-box;
        vertical-align: middle;
        margin-inline-start: 16px;
        margin-block-start: 18px;

        &:hover {
            background: 0 0;
            border: 1px solid #e84620;
            color: #fff;
            background-color: #e84620;
            cursor: pointer;
            display: inline-block;
            font-weight: 400;
            padding: 9px 35px;
            font-size: 1.15384615rem;
            border-radius: 30px;
            line-height: 1.92307692rem;
            box-sizing: border-box;
            vertical-align: middle;
            margin-inline-start: 16px;
            margin-block-start: 18px;
        }

        @include mobile {
            width: unset;

            &:hover {
                cursor: pointer;
                display: inline-block;
                font-weight: 400;
                padding: 9px 35px;
                font-size: 1.15384615rem;
                border-radius: 30px;
                line-height: 1.92307692rem;
                box-sizing: border-box;
                vertical-align: middle;
                margin-inline-start: 16px;
                margin-block-start: 18px;
            }
        }
    }
}
