.Main_Privacy_container {
    h1 {
        font-family: 'Oswald',sans-serif;
        font-weight: 700;
        line-height: 1.1;
        font-size: 2.59615385rem;
        margin-block: 0 2.88461538rem;
    }

    h3 {
        font-weight: 700;
        line-height: 1.1;
        font-size: 1.82692308rem;
        margin-block-start: 2.16346154rem;
        margin-block-end: 1.44230769rem;
        text-transform: inherit;
    }

    p {
        font-size: 15px;
        margin-block-start: 0;
        margin-block-end: 1.44230769rem;
    }

    b, strong {
        font-weight: 700;
        font-size: 15px;
    }

    .Responsibility_description {
        p {
            margin-block-end: 0;
        }
    }

    .Reponsibility_data_list {
        li {
            margin-block-start: 0;
            margin-block-end: 60px;
            font-size: 15px;
            list-style: inside;
            margin-inline-start: 20px;

            &::before {
                display: none;
            }
        }    
    }

    ul {
        margin-block-start: 0;
        margin-block-end: 20px;
        
        li {
            margin-block-start: 0;
            margin-block-end: 1.44230769rem;
            font-size: 15px;
            list-style: inside;
            margin-inline-start: 20px;

            &::before {
                display: none;
            }
        }     
    }

    .List-Style {
        margin-block-start: 0;
        margin-block-end: 3.60576923rem;

        li {
            margin-block-start: 0;
            margin-block-end: 1.44230769rem;
            font-size: 15px;
            list-style: circle;
            margin-inline-start: 60px;
    
            &::before {
                display: none;
            }
        }
    }

    ol {
        margin-block-start: 0;
        margin-block-end: 3.60576923rem;
        
        li {
            margin-block-start: 0;
            margin-block-end: 1.44230769rem;
            font-size: 15px;
        }
    }
    
    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        max-width: 100%;
    }

    tbody {
        font-size: 15px;
    }

    table>thead>tr>th, table>tbody>tr>th, table>tfoot>tr>th, table>thead>tr>td, table>tbody>tr>td, table>tfoot>tr>td {
        padding: 8px 15px;
    }

    table>tbody>tr>td, table>tfoot>tr>td {
        vertical-align: top;
    }

    table>thead>tr>th, table>thead>tr>td {
        vertical-align: bottom;
    }

    table th {
        text-align: start;
        background-color: transparent;
    }
    
    table tbody  tr {
        border: none;
    }

    @include mobile {
        padding-inline: 15px;
    }
}
