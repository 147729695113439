.Faq-Container {
    @include mobile {
        padding: 0 20px;
    }
    
    h1 {
        font-weight: 700;
        line-height: 1.1;
        font-size: 2.59615385rem;
        margin-block: 0 2.88461538rem;
        color: #212529;
    }

    h3 {
        font-weight: 700;
        line-height: 1.1;
        font-size: 1.82692308rem;
        margin-block: 2.16346154rem 1.44230769rem;
        color: #0a0903;
        text-transform: inherit;
    }
    
    p {
        margin-block: 0 1.44230769rem;
        font-size: 15px;
        color: #0a0903;

        a {
            color: #e84620;
            text-decoration: none;
            font-size: 15px;
            font-weight: 400;
        }
    }
}

