/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Menu {
    &-MenuWrapper {
        @include desktop {
            margin-block-start: 0;
        }
    }

    &-SubItemWrapper {
        @include desktop {
            display: flex;
            margin-inline-end: 3rem;
        }

        @include ultra-narrow-desktop {
            margin-inline-end: 10px;
        }
    }

    &-MainCategories {
        .Menu-ItemList {
            @include desktop {
                height: 66px;
                align-items: center;
                margin-inline-start: 7px;
            }
        }

        .Menu-Item:not(:first-of-type) {
            @include desktop {
                margin-inline-start: 4.8rem;
            }

            @include tablet {
                margin-inline-start: 2rem;
            }
        }
    }

    &-Item:last-child {
        margin-block-end: 0;
    }

    &-ItemCaption_type_main {
        text-transform: capitalize;
        font-weight: normal;
        line-height: 20px;
        letter-spacing: 0;
        color: #000000;

        @include desktop {
            font-size: 14px;
            padding: 0 0 20px;
            margin: 0;
        }

        @include mobile {
            padding: 6px 0;
            font-size: 16px;
            margin: 0;
            display: flex;
            align-items: center;
        }

        @include ultra-narrow-desktop {
            font-size: 14px;
        }
    }

    &-Link_isHovered {
        .Menu-ItemCaption_type_main {
            @include desktop {
                border-bottom: 3px solid var(--primary-base-color);
            }
        }
    }

    &-ItemCaption_type_subcategory {
        @include desktop {
            padding: 19px 37px 18px 16px;
            margin-block-start: 0;
            height: 56px;
            width: 223px;
            font-size: 16px;
            text-align: start;
            letter-spacing: 0;
            text-transform: capitalize;
            font-weight: normal;
        }

        @media (min-width: 1281px) and (max-width: 1395px) {
            width: 190px;
        }

        @include narrow-desktop {
            width: 173px;
        }

        @include ultra-narrow-desktop {
            width: 145px;
            font-size: 14px;
        }
    }

    &-ItemList-sub_type_subcategory {
        @include desktop {
            max-width: 650px;
        }
    }

    &-SubCategoriesWrapperInner_isVisible {
        @include desktop {
            position: absolute;
            background: white;
            max-width: 1440px;
            width: 100%;
        }
    }

    &-SubCategories {
        @include desktop {
            padding: 3rem 3rem 3rem 3rem;
            border-top: 3px solid #FFF5F4;
            box-shadow: 0px 6px 10px #0000001A;
        }

        @include ultra-narrow-desktop {
            padding-inline-start: 20px;
            padding-inline-end: 20px;
        }
    }

    &-SubMenu {
        @include desktop {
            display: grid;
            grid-template-columns: 0fr 3fr;
        }

        .Menu-ItemList {
            @include desktop {
                display: flex;
                flex-direction: column;
            }
        }
    }

    &-SubItemWrapper.active {
        @include desktop {
            background: #FFF5F4 0 0 no-repeat padding-box;
        }

        .Menu-ItemSvg {
            @include desktop {
                fill: var(--primary-base-color);
                position: absolute;
                inset-inline-end: 16px;
            }
        }

        .Menu-ItemCaption {
            @include desktop {
                color: var(--primary-base-color);
            }
        }
    }

    &-SubLevelDesktop {
        @include desktop {
            padding: 19px 52px;
            max-width: 650px;
            width: 100%;
        }

        @include ultra-narrow-desktop {
            padding: 19px 19px 19px 42px;
        }

        .Menu-ItemList {
            @include desktop {
                display: grid;
                grid-template-columns: auto auto auto;
                grid-gap: 2.5rem;
            }

            @include ultra-narrow-desktop {
                grid-gap: 36px 10px;
            }
        }

        .Menu-Link {
            &:first-child {
                .Menu-ItemCaption {
                    @include desktop {
                        color: var(--primary-base-color);
                    }

                    span {
                        @include desktop {
                            border-bottom: 1px solid var(--primary-base-color);
                        }
                    }
                }
            }
        }

        .Menu-ItemCaption {
            @include desktop {
                text-align: start;
                letter-spacing: 0;
                color: #0F0F0F;
                text-transform: capitalize;
                font-size: 16px;
            }

            @include ultra-narrow-desktop {
                font-size: 14px;
                width: unset;
            }

            &:hover {
                color: var(--primary-base-color);
            }
        }
    }

    &-CurrentMainCategory {
        letter-spacing: 0px;
        color: #0F0F0F;
        font-size: 16px;
        font-weight: bold;
        background: #FAFAFA;
        margin-block-end: 16px;

        a {
            color: #0F0F0F;
            text-transform: capitalize;
            padding: 10px 0 10px 16px;
            display: inherit;
        }
    }

    &-ItemList-sub-side-image {
        @include desktop {
            max-width: 330px;
            max-height: 419px;
            width: 100%;
            height: 419px;
        }

        @media (min-width: 811px) and (max-width: 1406px) {
            height: 100%;
        }

        .Image {
            @include desktop {
                width: 100%;
                height: 100%;
                padding: 51%;
            }

            @media (min-width: 1161px) and (max-width: 1406px) {
                padding: 56%;
            }
        }
    }

    &-ItemList-sub-container {
        @include desktop {
            display: grid;
            grid-template-columns: 2fr 1fr;
            border-left: 2px solid #9D9D9D;
        }
    }

    &-ItemList-sub-bottom-image {
        @include desktop {
            height: 88px;
            width: 100%;
            padding: 0 16px;
        }

        @include mobile {
            display: none;
        }

        .Image {
            @include desktop {
                width: 100%;
                height: 100%;
            }
        }
    }

    &-Wholesale {
        @include mobile {
            padding: 24px 0;
            border-bottom: 1px solid #F4F4F4;
        }

        .switcher-div {
            @include mobile {
                text-align: center;
            }
        }
    }

    &-WholesaleLogin {
        .switcher-div {
            @include mobile {
                text-align: center;
            }
        }
    }

    &-Switcher {
        @include mobile {
            border-bottom: 1px solid #F4F4F4;
            display: flex;
            flex-direction: column;
            // justify-content: center;
            // align-items: center;
            padding: 0 0 25px 0;
            grid-template-columns: 1fr 1fr;
            grid-gap: 3rem;
        }

        .CurrencySwitcher {
            @include mobile {
                margin: 0 0 0 auto;
                // max-width: 79px;
                width: 100%;
            }

            #CurrencySwitcherList {
                background: #ffffff;

                @include mobile {
                    text-align: start;
                    background: none;
                    border: none;
                    box-shadow: none;
                    margin-inline-start: 5px;
                    margin-inline-end: 1rem;
                }
            }
        }

        .ChevronIcon_direction_bottom {
            margin-inline-end: 10px;
        }

        .FieldSelect-Clickable {
            width: fit-content;

            @include mobile {
                width: auto;
            }
        }

        .StoreSwitcher {
            @include mobile {
                margin: 0 0 0 0px;
                width: 100%;

                button {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }
            }
        }

        .StoreSwitcher, .CurrencySwitcher {
            @include mobile {
                border-bottom: none;
            }
        }
    }

    &-MyQuote {
        @include mobile {
            display: flex;
            align-items: center;
            padding: 24px 0 21px 0;
            border-bottom: 1px solid #F4F4F4;
        }

        .quote-icon {
            @include mobile {
                height: 24px;
                margin-inline-end: 12px;
            }
        }

        .quote-label {
            @include mobile {
                .Header-quote-btn {
                    font-size: 16px;
                    color: #000000;
                }

                padding-block-start: 5px;
            }
        }
    }

    &-ItemList {
        @include mobile {
            padding: 25px 0;
        }
    }

    &-CategoryLabel {
        @include mobile {
            padding: 30px 0 0 0;
            font-size: 12px;
            letter-spacing: 0px;
            color: var(--primary-base-color);
        }
    }

    &-ItemSvg {
        @include mobile {
            position: absolute;
            inset-inline-end: 0;
            fill: var(--color-black);
        }
    }

    &-ItemList_type_mobileSubMain {
        @include mobile {
            padding: 20px 0;
        }

        .Menu-ItemCaption_type_subcategory {
            @include mobile {
                padding: 10px 0 10px 16px;
                letter-spacing: 0px;
                color: #0F0F0F;
                font-size: 16px;
                font-weight: bold;
                background: #FAFAFA;
                margin: 0 0 16px 0;

                .Menu-ItemSvg {
                    display: none;
                }

                .Menu-ExpandedState {
                    inset-inline-end: 16px;
                }

                .ChevronIcon {
                    fill: #0F0F0F;
                }
            }
        }

        .Menu-ItemCaption_isExpanded {
            @include mobile {
                color: var(--primary-base-color);
                background: #FFF5F4;

                .ChevronIcon {
                    fill: var(--primary-base-color);
                }
            }
        }

        .Menu-SubMenu_isVisible {
            @include mobile {
                padding: 0 0 0 16px;
                border-left: 1px solid #CBCBCB;
                background: none;
                margin-block-end: 16px;
            }

            .Menu-ItemCaption_type_subcategory {
                @include mobile {
                    letter-spacing: 0px;
                    color: #707070;
                    font-size: 14px;
                    background: none;
                    font-weight: normal;
                    margin: 0;
                    padding: 0;

                    svg {
                        display: none;
                    }
                }
            }

            .Menu-ItemList_type_subcategory {
                @include mobile {
                    display: grid;
                    grid-template-columns: auto auto;
                    grid-gap: 16px 45px;
                    padding: 0;
                }
            }

            .Menu-SubItemWrapper {
                &:first-child {
                    .Menu-ItemCaption_type_subcategory {
                        @include mobile {
                            color: var(--primary-base-color);
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    &-Mobile-submenu-image {
        .Menu-Image .Image {
            @include mobile {
                width: 100%;
                height: 194px;
            }

            img {
                @include mobile {
                    object-fit: cover;
                }
            }
        }
    }
}

.Header .Menu-MenuWrapper {
    @include desktop {
        max-width: 1440px;
    }
}
