.termsandconditionsmain {
    @include mobile {
        padding: 20px 15px;
    }

    .termsandconditions_overview {
        margin-block-end: 36px;

        .termsandconditionsheading {
            font-size: 24px;
            margin-block-end: 20px;
        }

        .termsandconditions_normalfont {
            font-size: 14px;
            margin-block-end: 20px;
        }

        .termsandconditionsheading_normal {
            font-size: 14px;
            margin-block-end: 20px;
        }

        .termsandconditions_boldfont {
            color: #707070;
            margin-block-end: 10px;
        }

        .termsandconditions_redfont {
            color: #E73B25;
        }
    }
}
