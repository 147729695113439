/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Banner {
    @include  desktop {
        width: 99vw;
        margin-inline-start: calc(50% - 50vw);
    }

    .BannerimgContainer {
        position: relative;
        margin: 0 auto;

        .BannerImg {
            object-fit: contain;
            width: 100%;
            cursor: pointer;
        }

        .BannerImg.onlyMobile {
            @include  desktop {
                display: none;
            }
        }

        .BannerImg.onlyDesktop {
            @include mobile {
                display: none;
            }
        }
    }
}

@media (max-width: 425px) {
    .Banner {
        .BannerimgContainer {
            .BannerSpan {
                .BannerImg {
                    object-fit: cover;
                }
            }
        }
    }
}
