.seedsman_main_wholesale {
    width: 100%;
    max-width: 1325px;
    margin: auto;

    @include mobile {
        width: unset;
        padding: 0 14px;
    }

    @include tablet {
        width: unset;
        padding: 0 14px;
    }

    .seedsman_date {
        display: flex;
        justify-content: flex-end;
        line-height: 28px;
        color: grey;
        font-size: 13px;
        border-bottom: 1px dotted #d5d5d5;
        font-weight: normal;
    }

    .wholesale_header {
        font-weight: 700;
        line-height: 1.1;
        font-size: 2.59615385rem;
        margin-block-start: 50px;
        margin-block-end: 2.88461538rem;
        border-top: 1px dotted #d5d5d5;
        // line-height: 100px;

        @include mobile {
            text-align: start;
        }

        @include tablet {
            text-align: start;
        }
    }
    
    .seed_subheader {
        font-size: 20px;
        font-weight: strong;
        color: red;
        margin: 20px 0 8px 0;
        padding: 0;
        text-decoration: underline;

        @include mobile {
            line-height: 27px;
            text-align: start;
        }

        @include tablet {
            line-height: 27px;
            text-align: start;
        }
    }

    .wholesale_para {
        font-size: 14px;
        font-weight: normal;
        color: #333333;
        margin: 0px 0px 8px 30px;
        padding: 0;
    }

    .wholesale_main_para {
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 1.5;
        color: #212529;
    }

    .wholesale_main_list {
        font-size: 13px;
        font-weight: normal;
        color: #333333;

        .wholesale_list {
            margin-block-start: 0;
            margin-block-end: 1.44230769rem;
            list-style-type: disc;
            margin-inline-start: 50px;
            font-size: 14px;

            &::before {
                position: fixed;
            }
        }
    }

    .top {
        border-bottom: 1px dotted #d5d5d5;
    }

    .wholesale_main_footer {
        text-align: center;
    }

    .bottom {
        border-bottom: 1px solid silver;
    }

    .wholesale_footer {
        text-align: center;
        font-size: 14px;
        font-weight: normal;
        color: #333333;
        margin: 0 0 20px 0;
        padding: 0;

        .wholesale_link {
            font-weight: bold;
            font-size: 12px;
            color: red;
            padding-inline-start: 10px;
        }
    }

    .seedsman_main_wholesale .wholesale_footer {
        text-align: center;
        font-size: 10px;
        font-weight: normal;
        color: #333333;
    }
}
