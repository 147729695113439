/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --header-logo-height: 42px;
    --header-nav-height: 42px;
    --custom-header-logo-width: 157px;
    --header-before-nav-height: 80px;

    &:has(.Header .ins-sticky-placeholder-240) {
        --header-top-menu-height: 67px;
        --menu-total-height: 66px;
    }

    @include desktop {
        --header-height: calc(var(--header-nav-height) + var(--header-before-nav-height) + var(--header-top-menu-height) + var(--menu-total-height));
    }

    @include mobile {
        --header-height: 132px;
        --header-nav-height: 132px;
        --custom-header-logo-height: 32px;
        --custom-header-logo-width: 123px;
    }
}

.Header {
    position: inherit;

    &-LogoWrapper {
        width: var(--custom-header-logo-width);

        &_isVisible {
            @include mobile {
                margin: 20px auto 20px 20px;
                height: var(--custom-header-logo-height);
            }
        }
    }

    &-Nav {
        @include desktop {
            padding-inline-start: 45px;
            padding-inline-end: 45px;
            grid-template-columns: minmax(var(--header-logo-width), 0fr) minmax(auto, 859px) 1fr;
        }

        @include narrow-big-desktop {
            grid-template-columns: minmax(var(--header-logo-width), 0fr) minmax(auto, 600px) 1fr;
        }

        @include mobile {
            flex-wrap: wrap;
            padding-inline-start: 24px;
            padding-inline-end: 24px;
        }
    }

    &-Button {
        &_type {
            &_menu,
            &_minicart {
                @include desktop {
                    height: 24px;
                    margin-inline-start: 35px;
                }
            }

            &_minicart {
                @include desktop {
                    margin-inline-start: 35px;
                }

                @include tablet {
                    margin-inline-start: 15px;
                }
            }
        }
    }

    &-Wrapper {
        margin-block-end: unset;
    }

    &-TopMenu {
        justify-content: center;

        @include desktop {
            padding: 10px 20px;
            background: #FCECDB;
            margin: 0;
            max-width: 100%;
        }
    }

    &-BeforeNav {
        height: var(--header-before-nav-height);

        @include desktop {
            padding-inline-start: 45px;
            padding-inline-end: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &-Switcher {
        column-gap: 3rem;

        .switcher-div {
            font-size: 16px;
            letter-spacing: 0;
            color: #000000;
            text-transform: capitalize;
            cursor: pointer;
            display: flex;
            align-items: center;

            &-link {
                font-weight: normal;
                color: #000000;
                font-size: 14px;
            }

            @include desktop {
                text-align: end;
            }

            .Field-Wrapper .Field_type_select .FieldSelect-Select {
                background-color: #FFFFFF;
                border: unset;
                text-align: center;
                box-shadow: unset;
                width: 100%;
            }

            .FieldSelect-Select {
                @include desktop {
                    font-size: 14px;
                    letter-spacing: 0;
                    color: #000000;
                    text-transform: capitalize;
                }
            }

            .StoreSwitcher-Title {
                @include desktop {
                    font-size: 14px;
                    letter-spacing: 0;
                    color: #000000;
                    text-transform: capitalize;
                }
            }

            .StoreSwitcher .StoreItem-Item {
                @include desktop {
                    font-size: 16px;
                    letter-spacing: 0;
                    color: #000000;
                    text-transform: capitalize;
                }
            }

            .CurrencySwitcher .FieldSelect-Option {
                @include desktop {
                    font-size: 14px;
                    letter-spacing: 0;
                    color: #000000;
                    text-transform: capitalize;
                }
            }

            .CurrencySwitcher .FieldSelect {
                @include desktop {
                    padding-inline-start: 5px;
                    width: auto;
                }
            }

            .CurrencySwitcher .FieldSelect .ChevronIcon {
                inset-inline-end: 0px;
                height: 8px;
                width: 10px;
            }

            .StoreSwitcher .ChevronIcon {
                height: 8px;
                width: 10px;
            }
        }
    }

    &-wholesale-btn {
        border: 1px solid #E53D2F;
        border-radius: 4px;
        padding: 12px 18px;
        letter-spacing: 0;
        font-weight: bold;
        color: var(--primary-base-color);
        font-size: 14px;

        &:hover {
            background: var(--primary-base-color);
            color: #ffffff;
            border: 1px solid var(--primary-base-color);
        }

        @include mobile {
            padding: 0;
            font-size: 14px;
            height: 36px;
            max-width: 293px;
            width: 100%;
        }
    }

    &-MyAccount {
        @include tablet {
            margin-inline-start: 15px;
        }
    }

    &-MyAccountWrapper {
        display: flex;
        cursor: pointer;

        @include desktop {
            color: #000000;

            svg.usericon {
                transform: scale(1.3);
            }

            &:hover {
                .log-in-txt {
                    color: var(--primary-base-color);
                }

                svg.usericon {
                    fill: var(--primary-base-color);
                }

                svg.usericon path {
                    stroke: var(--primary-base-color);
                }
            }

            .log-in-txt {
                padding-block-start: 6px;
                font-size: 16px;
                font-weight: normal;
                letter-spacing: 0px;
                text-transform: capitalize;
                padding-inline-start: 10px;
                cursor: pointer;
            }
        }

        @include mobile {
            width: 24px;
            height: 24px;
        }
    }

    &-CompareButtonWrapper {
        margin-inline-start: 32px;
        height: 24px;
    }

    &-WishlistButtonWrapper {
        margin-inline-start: 35px;
        height: 24px;
        cursor: pointer;
        transform: scale(1.3);

        @include mobile {
            width: 24px;
            height: 24px;
            margin-inline-start: 0;
            transform: scale(1)
            // margin-inline-end: 16px;
        }

        @include tablet {
            margin-inline-start: 20px;
        }

        .Header-Button {
            opacity: 1;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }

        &:hover {
            svg {
                fill: var(--primary-base-color);
            }

            svg path {
                stroke: var(--primary-base-color);
            }
        }
    }

    &-IconsWrapper {
        padding-block-end: 4px;

        @include desktop {
            margin-inline-start: 20px;
        }
    }

    &-MinicartButtonWrapper {
        @include mobile {
            width: 24px;
            height: 24px;
        }

        .minicart-svg {
            inset-block-start: -1px;
            transform: scale(1.2);

            @include mobile {
                transform: scale(1);
            }
        }

        &:hover {
            svg {
                fill: var(--primary-base-color);
            }
        }
    }

    &-News {
        display: flex;
        text-align: center;
        margin: auto;
        align-items: center;
        max-width: var(--content-wrapper-width);
        padding-inline: 45px;

        p {
            margin-block-end: 0px;
        }

        span {
            font-size: 14px;
            text-align: center;
            letter-spacing: 0;
            color: #0F0F0F;
        }

        .bold {
            font-weight: bold;
        }
    }

    &-MenuButton {
        @include mobile {
            display: none;
        }

        &_isVisible {
            display: block;

            @include mobile {
                height: 24px;
            }
        }
    }

    &-MobileIconsWrapper {
        @include mobile {
            display: none;
        }

        &_isVisible {
            @include mobile {
                display: flex;

                @include mobile {
                    grid-gap: 24px;
                }

                // button:not(:last-of-type) {
                //     margin-inline-end: 16px;
                // }
            }
        }
    }

    &-Title {
        @include mobile {
            display: none;
        }

        &_isVisible {
            @include mobile {
                display: block;
            }
        }
    }

    .SearchField-SearchIcon {
        inset-inline-end: 0
    }

    .SearchField-Input {
        border-radius: 4px;

        @include desktop {
            font-size: 14px;
        }

        &_isActive {
            @include desktop {
                padding-inline-start: 52px;
            }
        }
    }

    &-Language-switcher {
        display: flex;
        align-items: center;
        min-width: auto;

        .ChevronIcon {
            height: 8px;
            width: 10px;

            @include mobile {
                width: 10px;
                height: 10px;
            }
        }

        .flag-icon {
            width: 20px;
            height: 20px;

            @include mobile {
                width: 26px;
                height: 26px;
            }
        }
    }

    &-SearchField {
        display: block;
        grid-column: 2 / 3;
        max-width: 798px;
        margin: 0;

        @include desktop {
            width: 100%;
            margin-inline-start: 6.1rem;
        }

        @include ultra-narrow-desktop {
            margin-inline-start: 0;
        }

        @include mobile {
            display: none;
        }

        &_isVisible {
            height: 40px;

            @include mobile {
                display: block;
                margin-block-start: 0;
                margin-block-end: 20px;
                width: 100%;
            }
        }
    }

    &-SearchFieldContainer_isActive {
        @include desktop {
            width: 75vw;
            z-index: 11;
            position: relative;
            transition: .5s;
        }
    }

    .SearchField {
        @include desktop {
            max-width: unset;
        }
    }

    .SearchField_isActive {
        @include desktop {
            max-width: 1092px;
            width: 100%;
            position: absolute;
            transition: .5s;
        }

        input#search-field {
            @include desktop {
                height: 69px;
                border-radius: 4px 4px 0 0;
                border: 1px solid #B7B7B7;
            }
        }

        .SearchField-SearchInnerWrapper {
            &::before {
                @include desktop {
                    content: '';
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    background: url("src/util/Images/icSearch.svg");
                    z-index: 1;
                    inset-block-start: 26px;
                    inset-inline-start: 24px;
                }
            }
        }
    }

    &-SearchFieldBackgroundHeader {
        display: none;

        &_isActive {
            @include desktop {
                display: block;
                max-width: 100vw;
                width: 100vw;
                height: 100vh;
                position: absolute;
                background: #0F0F0F;
                opacity: 0.25;
                z-index: 10;
            }
        }
    }

    &_name_search {
        @include mobile {
            height: 91px;
            //box-shadow: 0 3px 8px #00000008;
        }

        .Header-Nav {
            @include mobile {
                padding: 0;
            }
        }

        .Header-SearchField {
            @include mobile {
                max-width: unset;
            }
        }

        .SearchField {
            &-SearchInnerWrapper {
                @include mobile {
                    width: 100%;
                    margin: 0 auto;
                    padding: 0 24px;
                }

                &::before {
                    @include mobile {
                        content: '';
                        position: absolute;
                        width: 16px;
                        height: 16px;
                        background: url("src/util/Images/icSearch.svg");
                        z-index: 1;
                        inset-block-start: 11px;
                        inset-inline-start: 24px;
                    }
                }


                @include tablet {
                    width: 92%;
                }
            }

            &-CloseIcon {
                @include mobile {
                    inset-inline-end: 31px;
                }
            }

            &-Input_isActive {
                @include mobile {
                    border-bottom: 1px solid #9D9D9D;
                    border-top: unset;
                    border-left: unset;
                    border-right: unset;
                    border-radius: 0;
                    font-size: 16px;
                    padding-inline-start: 30px;
                }
            }

            &-SearchData_isActive {
                @include mobile {
                    width: 100vw;
                    height: 100vh;
                    // background: white;
                    //margin-block-start: 39px;
                }
            }

            &-HeaderBorder_isActive {
                @include mobile {
                    height: 35px;
                    box-shadow: 0 3px 8px #00000008;
                }
            }
        }

        .SearchOverlay {
            &-Results {
                @include mobile {
                    // margin-block-start: 4px;
                    padding: 0 24px 24px;
                    overflow-y: scroll;
                    max-height: calc(100vh - 84px);
                }
            }
        }
    }

    &_name_category {
        @include mobile {
            border-bottom: 1px solid var(--primary-divider-color);
        }
    }

    &_isCheckout {
        .Header {
            &-Nav {
                height: unset;
            }
        }

        .Header-IconsWrapper {
            justify-content: flex-end;
        }
    }
}

body.search-index {
    @include mobile {
        overflow: hidden;
    }
}

.ConfigPopup {
    .create-account-type {
        padding: 20px 20px 30px 20px;
        row-gap: 17px;
        position: relative;
        
        .popup-title {
            font-size: 20px;
            // padding: 20px 5px 5px 20px;
            text-align: center;
        }

        .close-btn-container {
            position: absolute;
            inset: -15px -12px auto auto;

            @include mobile {
                inset: -23px -10px auto auto;
            }
        }

        .Popup-CloseBtn {
            inset-block-start: -5rem;
            inset-inline-end: 0;
            padding: 5px;
            position: static;
            margin-inline-end: 0px;

            svg {
                width: 20px;
                height: 20px;
            }
        }

        // &.popup {
        //     max-width: none;
        //     width: auto;
        // }

        .commercial,.wholesale {
            color: #e73b25;
            padding: 15px;
            border: 1px solid #E53D2F;
            border-radius: 5px;
            text-align: center;
            // margin: 20px auto;
            font-weight: bold;
            cursor: pointer;

            &:hover {
                color: white;
                background: #e73b25;
            }
        }
    }
}

.LoginAccount-InnerWrapper {
    .create-account-type {
        button {
            margin: 0px 0px;
        }
    }
}

@include desktop {
    .LoginAccount-InnerWrapper {
        .create-account-type {
            button {
                margin: 0px 0px;
            }
        }
    }
}
