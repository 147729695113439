/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.PasswordChangeForm {
    margin-block-start: 24px;

    @include mobile {
        margin-block-start: 28px;
    }

    &-PasswordStrengthChecker {
        width: 50%;
        margin-block-start: 6px;

        @include mobile {
            width: 70%;
        }

        p {
            background: #FFF5F4;
            padding: 8px;
            font-size: 12px;
            text-align: center;

            @include mobile {
                font-size: 10px;
                padding: 6px;
                text-align: start;
            }

            span {
                color: var(--primary-base-color);
            }
        }
    }
}
