/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --footer-logo-width: 216px;
    --footer-logo-height: 57px;
    --footer-top-height: 120px;
    --footer-copyright-height: 96px;
    --secondary-base-color: #fff;
    --footer-background-color: #fff;
}

.Footer {
    @include mobile {
        background-color: var(--footer-background-color);
        padding-block-start: 35px;
    }

    &-Top {
        background-color: var(--footer-background-color);
        
        @include mobile {
            border-top: 4px solid #e9e9e9;
            margin: 0 24px;
        }
    }

    &-LogoWrapper {
        width: 140px;
        height: 37px;

        @include desktop {
            width: var(--footer-logo-width);
            height: var(--footer-logo-height);
            position: absolute;
            inset-block-start: 58px;
            inset-inline-start: 48px;
        }

        @include ultra-narrow-desktop {
            inset-block-start: 58px;
            inset-inline-start: 0;
        }

        @include mobile {
            margin-block-end: 36px;
            margin-inline-start: 24px;
        }

        .Image.Image_imageStatus_IMAGE_LOADED.Logo {
            --image-default-background: #fff0;
        }
    }

    .StoreSwitcherV2 {
        @include desktop {
            margin-block-start: 2rem;
            cursor: pointer;
            z-index: 10;
        }

        @include mobile {
            padding-inline: 24px;
            border-bottom: 1px solid #f4f4f4;
        }

        &-Button {
            font-size: 14px;

            @include mobile {
                font-size: 14px;
                font-weight: 600;
            }   
        }
    }

    .ExpandableContent-Heading.isDesktop-ExpandableContentHeading {
        font-size: 16px;
        font-weight: 600;

        @include mobile {
            font-size: 14px;
        }
    }

    .ExpandableContent.isDesktop .ExpandableContent-Button {
        @include desktop {
            padding: 0 0 2rem;
        }

        @include mobile {
            padding-block-start: 0;
            padding-block-end: 23px;
        }
    }

    .ExpandableContent:last-of-type {
        @include mobile {
            border-top: 0;
            border-bottom: 1px solid #F4F4F4;
        }
    }

    &-Column_class_logo-column.onlyDesktop {
        @include mobile {
            display: none;
        }
    }

    &-ColumnContent_class_security-column.onlyMobile {
        @include desktop {
            display: none;
        }
    }

    &-ColumnContent_class_payment-provider.onlyMobile {
        @include desktop {
            display: none;
        }

        @include mobile {
            padding-block-start: 16px;
        }
    }

    &-column-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: var(--content-wrapper-width);

        @include mobile {
            flex-direction: column;
            padding: 16px 24px;
        }

        @include desktop {
            padding: 56px 45px 56px 48px;
            border-top: 6px solid #F7F7F7;
            border-bottom: 6px solid #F7F7F7;
        }

        @include ultra-narrow-desktop {
            padding: 56px 0 56px 0;
        }

        li::before {
            display: none;
        }
    }

    &-Column_class_logo-column {
        @include desktop {
            margin-block-start: 9rem;
        }
    }

    &-Columns {
        padding: 0;
        justify-content: unset;
        display: block;
    }

    &-Content {
        background-color: var(--footer-background-color);

        .logo-block {
            @include desktop {
                margin-block-start: -20px;
            }
        }

        @include desktop {
            padding-inline-start: 60px;
            padding-inline-end: 60px;
        }
    }

    &-footerone-column-content.first-column {
        display: flex;
        padding: 40px 0 36px 0;

        @include mobile {
            padding: 49px 0 20px 0;
            justify-content: flex-start;
            border-bottom: 1px solid #F4F4F4
        }

        &::before {
            @include mobile {
                content: 'Security:';
                position: absolute;
                inset-block-start: 20px;
                inset-inline-start: 0;
                font-size: 14px;
                font-weight: bold;
            }
        }

        .column-image:not(:first-of-type) {
            margin-inline-start: 25px;

            @include mobile {
                margin-inline-start: 24px;
            }
        }

        .column-image img {
            height: 27px;

            @include mobile {
                height: 24px;
                width: auto;
            }
        }
    }

    &-footerone-column-content {
        justify-content: center;

        @include desktop {
            justify-content: flex-end;
        }
    }

    &-footerone-column-content.second-column {
        display: flex;

        @include mobile {
            padding: 46px 0 20px 0;
            justify-content: flex-start;
            border-bottom: 1px solid #F4F4F4
        }

        &::before {
            @include mobile {
                content: 'Follow Us:';
                position: absolute;
                inset-block-start: 20px;
                inset-inline-start: 0;
                font-size: 14px;
                font-weight: bold;
            }
        }

        .icon {
            svg {
                @include mobile {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .icon:not(:first-of-type) {
            margin-inline-start: 24px;

            @include mobile {
                margin-inline-start: 16px;
            }
        }
    }

    &-link-column {
        margin-block-start: -5px;

        .second-link-head-h3 {
            font-size: 16px;
            margin: 0;
        }

        .second-link:not(:first-of-type) {
            margin-block-start: 16px;
        }

        .second-link a {
            font-size: 14px;
            color: #000000;
            font-weight: normal;
            letter-spacing: 0;
            text-align: start;
        }
    }

    &-contact-block {
        display: flex;
        justify-content: flex-start;

        .contact-svg {
            display: flex;
            align-items: center;
        }
    }

    .contact-data.Footer-link-data {
        margin-inline-start: 13px;
    }

    &-link-data {
        font-size: 14px;
        color: #000000;
        font-weight: normal;
        letter-spacing: 0;
        display: flex;
        align-items: center;
        text-align: start;
        justify-content: flex-start;
    }

    &-CopyrightContentWrapper {
        padding-inline: 0;
        background-color: var(--footer-background-color);

        @include desktop {
            text-align: start;
        }

        @include mobile {
            padding: 0 24px;
        }
    }

    &-CopyrightContent {
        @include desktop {
            display: flex;
            align-items: center;
            padding: 0;
            justify-content: space-between;
            flex-direction: inherit;
        }

        @include mobile {
            padding: 4px 0 16px 0;
            flex-direction: column-reverse;
            align-items: baseline;
            justify-content: flex-end;
        }
    }

    &-Copyright {
        padding-inline-start: 0;
        letter-spacing: 0;
        color: #000000;
        font-size: 14px;

        @include mobile {
            border-top: 4px solid #F7F7F7;
            text-align: center;
            padding-inline-end: 0;
            padding-block-start: 20px;
            width: 100%;
        }
    }

    &-payment-provider {
        display: flex;
        align-items: center;

        @include mobile {
            padding-block-start: 26px;
            padding-block-end: 18px;
        }

        &-header {
            @include desktop {
                letter-spacing: 0;
                color: #000000;
                font-size: 14px;
            }
        }

        &-div:not(:first-of-type) {
            @include desktop {
                margin-inline-start: 30px;
            }

            @include mobile {
                margin-inline-end: 30px;
            }
        }

        &-div {
            &:first-child {
                @include mobile {
                    display: none;
                }
            }
        }

        &::before {
            @include mobile {
                content: 'Payment Providers:';
                position: absolute;
                inset-block-start: 0;
                inset-inline-start: 0;
                font-size: 14px;
                font-weight: bold;
            }
        }
    }

    &-ColumnTitle {
        @include desktop {
            display: none;
        }

        @include mobile {
            font-size: 14px;
            padding-block-end: 23px;
            margin: 0;
            border-bottom: 1px solid #F4F4F4;
            font-weight: bold;
        }

        &::after {
            @include mobile {
                content: '';
                width: 2px;
                position: absolute;
                height: 13px;
                inset-inline-end: 6px;
                background-color: #000;
                inset-block-start: 2px;
                transition: transform .2s,-webkit-transform .2s;
                will-change: transform;
            }
        }

        &::before {
            @include mobile {
                content: '';
                width: 2px;
                height: 13px;
                background-color: #000;
                position: absolute;
                inset-inline-end: 6px;
                transform-origin: 50% 50%;
                transform: rotate( 90deg );
                inset-block-start: 2px;
            }
        }
    }

    &-ColumnContent.Footer-ColumnContent_class_logo-column {
        @include mobile {
            display: block;
        }
    }

    &-ColumnContent.Footer-ColumnContent_class_security-column {
        @include mobile {
            display: block;
        }
    }

    &-Columns .Footer-Column.Footer-Column_class_contact-column {
        @include mobile {
            margin-block-end: 0;
        }
    }

    &-ColumnTitle_class_logo-column {
        border-bottom: none;

        &::after,&::before {
            display: none;
        }
    }

    &-ColumnTitle_class_security-column {
        display: none;

        &::after,&::before {
            display: none;
        }
    }

    .second-link-head {
        @include mobile {
            display: none;
        }
    }

    &-Column {
        @include mobile {
            text-align: start;
        }

        &:not(:last-of-type) {
            @include mobile {
                margin-block-end: 20px;
            }
        }

        &:first-child {
            @include mobile {
                margin-block-end: 36px;
            }
        }
    }

    .Footer-ColumnTitle.active::after {
        @include mobile {
            transition: transform .2s,-webkit-transform .2s;
            will-change: transform;
            transform: rotate( 90deg );
        }
    }
}

.NavigationTabs {
    @include mobile {
        display: none;
    }
}
