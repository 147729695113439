@font-face {
    font-family: 'Lato';
    src: url(/style/fonts/Lato-Black.woff2) format('woff2'),url(/style/fonts/Lato-Black.woff) format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url(/style/fonts/Lato-BlackItalic.woff2) format('woff2'),url(/style/fonts/Lato-BlackItalic.woff) format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url(/style/fonts/Lato-Bold.woff2) format('woff2'),url(/style/fonts/Lato-Bold.woff) format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url(/style/fonts/Lato-BoldItalic.woff2) format('woff2'),url(/style/fonts/Lato-BoldItalic.woff) format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url(/style/fonts/Lato-Italic.woff2) format('woff2'),url(/style/fonts/Lato-Italic.woff) format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url(/style/fonts/Lato-Light.woff2) format('woff2'),url(/style/fonts/Lato-Light.woff) format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url(/style/fonts/Lato-LightItalic.woff2) format('woff2'),url(/style/fonts/Lato-LightItalic.woff) format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url(/style/fonts/Lato-Regular.woff2) format('woff2'),url(/style/fonts/Lato-Regular.woff) format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url(/style/fonts/Lato-Thin.woff2) format('woff2'),url(/style/fonts/Lato-Thin.woff) format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url(/style/fonts/Lato-ThinItalic.woff2) format('woff2'),url(/style/fonts/Lato-ThinItalic.woff) format('woff');
    font-weight: 100;
    font-style: italic;
}
$font-Lato: 'Lato';

body {
    font-family: $font-Lato;
}

//Inter fonts
@font-face {
    font-family: 'Inter';
    src: url(/style/fonts/Inter-ExtraLight.woff2) format('woff2');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url(/style/fonts/Inter-Thin.woff2) format('woff2');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url(/style/fonts/Inter-Light.woff2) format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url(/style/fonts/Inter-Regular.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url(/style/fonts/Inter-Medium.woff2) format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url(/style/fonts/Inter-SemiBold.woff2) format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url(/style/fonts/Inter-Bold.woff2) format('woff2');
    font-weight: 700;
    font-style: normal;
}

$font-Inter: 'Inter';


//montserrat font
@font-face {
    font-family: 'Montserrat';
    src: url(/style/fonts/montserrat/Montserrat-Black.woff2) format('woff2'),url(/style/fonts/montserrat/Montserrat-Black.woff) format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/style/fonts/montserrat/Montserrat-BlackItalic.woff2) format('woff2'),url(/style/fonts/montserrat/Montserrat-BlackItalic.woff) format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/style/fonts/montserrat/Montserrat-Bold.woff2) format('woff2'),url(/style/fonts/montserrat/Montserrat-Bold.woff) format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/style/fonts/montserrat/Montserrat-BoldItalic.woff2) format('woff2'),url(/style/fonts/montserrat/Montserrat-BoldItalic.woff) format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/style/fonts/montserrat/Montserrat-ExtraBold.woff2) format('woff2'),url(/style/fonts/montserrat/Montserrat-ExtraBold.woff) format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/style/fonts/montserrat/Montserrat-ExtraBoldItalic.woff2) format('woff2'),url(/style/fonts/montserrat/Montserrat-ExtraBoldItalic.woff) format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/style/fonts/montserrat/Montserrat-ExtraLight.woff2) format('woff2'),url(/style/fonts/montserrat/Montserrat-ExtraLight.woff) format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/style/fonts/montserrat/Montserrat-ExtraLightItalic.woff2) format('woff2'),url(/style/fonts/montserrat/Montserrat-ExtraLightItalic.woff) format('woff');
    font-weight: 200;
    font-style: italic;
}

// @font-face {
//     font-family: 'Montserrat';
//     src: url(/style/fonts/montserrat/Montserrat-Italic-VariableFont_wght.woff2) format('woff2'),url(/style/fonts/montserrat/Montserrat-Italic-VariableFont_wght.woff) format('woff');
//     font-weight: 100;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Montserrat';
//     src: url(/style/fonts/montserrat/Montserrat-Italic.woff2) format('woff2'),url(/style/fonts/montserrat/Montserrat-Italic.woff) format('woff');
//     font-weight: 100;
//     font-style: italic;
// }

@font-face {
    font-family: 'Montserrat';
    src: url(/style/fonts/montserrat/Montserrat-Light.woff2) format('woff2'),url(/style/fonts/montserrat/Montserrat-Light.woff) format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/style/fonts/montserrat/Montserrat-LightItalic.woff2) format('woff2'),url(/style/fonts/montserrat/Montserrat-LightItalic.woff) format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/style/fonts/montserrat/Montserrat-Medium.woff2) format('woff2'),url(/style/fonts/montserrat/Montserrat-Medium.woff) format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/style/fonts/montserrat/Montserrat-MediumItalic.woff2) format('woff2'),url(/style/fonts/montserrat/Montserrat-MediumItalic.woff) format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/style/fonts/montserrat/Montserrat-Regular.woff2) format('woff2'),url(/style/fonts/montserrat/Montserrat-Regular.woff) format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/style/fonts/montserrat/Montserrat-SemiBold.woff2) format('woff2'),url(/style/fonts/montserrat/Montserrat-SemiBold.woff) format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/style/fonts/montserrat/Montserrat-SemiBoldItalic.woff2) format('woff2'),url(/style/fonts/montserrat/Montserrat-SemiBoldItalic.woff) format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/style/fonts/montserrat/Montserrat-Thin.woff2) format('woff2'),url(/style/fonts/montserrat/Montserrat-Thin.woff) format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/style/fonts/montserrat/Montserrat-ThinItalic.woff2) format('woff2'),url(/style/fonts/montserrat/Montserrat-ThinItalic.woff) format('woff');
    font-weight: 100;
    font-style: italic;
}

// @font-face {
//     font-family: 'Montserrat';
//     src: url(/style/fonts/montserrat/Montserrat-VariableFont_wght.woff2) format('woff2'),url(/style/fonts/montserrat/Montserrat-VariableFont_wght.woff) format('woff');
//     font-weight: 100;
//     font-style: normal;
// }
