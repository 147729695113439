/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.SearchField {
    @include desktop {
        margin: 0;
        width: 100%;
    }

    &_isVisible {
        @include mobile {
            margin-block-start: unset;
        }
    }

    &-Input {
        // border: 1px solid #525252;
        border-radius: 4px;
        height: 40px;
        padding-inline-end: 32px;
    }

    &-SearchIcon {
        inset-block-start: calc(50% - 20px) !important;  /* stylelint-disable-line declaration-no-important */
        width: 48px !important;  /* stylelint-disable-line declaration-no-important */
        height: 40px !important;  /* stylelint-disable-line declaration-no-important */
        inset-inline-end: 0;
        background: var(--primary-base-color);
        border-radius: 0 4px 4px 0;

        .SearchIcon {
            fill: var(--color-white);
            width: 48px;
            inset-block-start: 7px;
        }
    }

    &-CloseIcon {
        inset-inline-end: 8px;
    }
}
