.Promotions_cms_page {
    padding: 0% 14%;

    @include mobile {
        padding: 0% 15px;
    }

    .Promotions_main_banner_wrapper {
        display: flex;

        @include mobile() {
            flex-direction: column;
            row-gap: 50px;
        }

        @include tablet() {
            flex-direction: column;
            row-gap: 50px;
        }
    }

    .Promotions_banner_image_wrapper {
        width: 50%;
        position: relative;

        @include mobile() {
            width: 100%;
        }

        @include tablet() {
            width: 100%;
        }

        .Promotions_banner_image_wrapper_image {
            object-fit: contain;

            img {
                object-fit: contain;

                @include mobile() {
                    width: 100%;
                    height: 300px;
                    object-fit: cover;
                }

                @include tablet() {
                    width: 100%;
                    height: 300px;
                    object-fit: cover;
                }
            }
        }

        .Promotions_banner_image_wrapper_text {
            height: 124px;
            width: 424px;
            padding: 20px;
            position: absolute;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 2px 2px 25px #e43d2f1a;
            border-radius: 6px;
            opacity: 1;
            inset-block-end: 13%;
            inset-block-end: -7%;
            font-size: 16px;
            font-weight: bold;

            @include mobile() {
                width: 252px;
                height: 104px;
                inset-inline-end: -2%;
                font-size: 12px;
            }

            @include tablet() {
                height: 104px;
                inset-inline-end: -2%;
                font-size: 12px;
            }

            @include desktop() {
                height: 104px;
                inset-inline-end: 17%;
                font-size: 16px;
            }
        }
    }

    .Promotions_banner_text_wrapper {
        margin-block-start: 65px;
        width: 45%;

        @include mobile() {
            width: 100%;
        }

        @include tablet() {
            width: 100%;
        }

        h2 {
            margin-block-end: 25px;
            font-size: 30px;
            white-space: nowrap;
        }

        .Promotions_banner_text_paragraph {
            margin-block-end: 24px;
            font-size: 16px;
        }

        .Promotions_banner_text_red_text {
            color: #e73b25;
            text-decoration: underline;
            font-weight: 500;
            font-size: 16px;
        }

        .Promotions_banner_text_paragraph_bold_text {
            font-weight: bold;
            font-size: 16px;

            .Promotions_banner_text_red_text {
                color: #e73b25;
                text-decoration: unset;
            }
        }
    }

    .Grid {
        margin-block-start: 65px;

        @include mobile() {
            margin-block-end: 15%;
        }

        @include tablet() {
            margin-block-end: 15%;
        }

        .Gridheading {
            font-family: "Montserrat", sans-serif;
            font-size: 30px;
            padding-block-end: 3%;
            color: #000000;
            text-align: center;

            @include mobile() {
                font-size: 20px;
            }

            @include tablet() {
                font-size: 20px;
            }
        }

        .Gridcontainer {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;

            @include mobile() {
                grid-gap: 4%;
                row-gap: 30px;
                grid-template-columns: repeat(1,1fr);
            }

            @include tablet() {
                grid-gap: 4%;
                row-gap: 30px;
            }

            .Gridimg {
                flex: 1 1 30%;

                @include mobile() {
                    flex: 48%;
                }

                @include tablet() {
                    flex: 48%;
                }
            }

            .Imagetxt {
                @include mobile() {
                    height: auto;
                    padding-block-end: 84%;
                }

                @include tablet() {
                    height: auto;
                    padding-block-end: 1%;
                }

                @include desktop {
                    padding-block-end: 84%;
                }

                a {
                    position: absolute;
                    display: inherit;
                    width: 100%;
                    height: 100%;
                }

                .Gridimg1 {
                    @include tablet {
                        width: 100%;
                    }

                    @include mobile {
                        width: 100%;
                    }
                }
            }

            .Textcontainer {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                background-color: #f2faf8;
                padding: 2% 5%;

                .Titleseed {
                    font-size: 14px;
                    color: #707070;
                    font-family: "Montserrat", sans-serif;

                    @include mobile() {
                        font-size: 12px;
                    }

                    @include tablet() {
                        font-size: 12px;
                    }
                }

                .Seedpack {
                    font-size: 20px;
                    color: #000000;
                    font-family: "Lato", sans-serif;
                    font-weight: bold;

                    @include mobile() {
                        font-size: 12px;
                    }

                    @include tablet() {
                        font-size: 12px;
                    }
                }

                .Shopnow {
                    font-size: 14px;
                    font-family: "Lato", sans-serif;
                    color: #e73b25;
                    text-decoration: underline;

                    &:hover {
                        cursor: pointer;
                    }

                    @include mobile() {
                        font-size: 12px;
                    }

                    @include tablet() {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
