/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.OrderedAddress {
    &-Shipping {
        background: #fff;
        padding: 2rem;
        margin-block-end: 2rem;

        .ShippingInfoContents {
            p {
                font-size: 16px;

                span {  
                    color: var(--primary-base-color);
                } 
            }
        }
    }

    &-Shipping:last-child {
        margin-block-end: 0;
    }

    &-Title {
        h4 {
            font-size: 16px;
            color: var(--primary-base-color);
            font-weight: bold;
            padding-block-end: 1rem;
            margin-block-end: 2rem;
            border-bottom: 1px solid #F2F2F2;
        }
    }
}
