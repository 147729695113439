/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.TrustedPartners {
    width: 100%;
    padding: 48px 60px;

    @include  mobile {
        padding: 20px;
    }

    .Showcase {
        &-section {
            text-align: center;
            align-items: center;
            display: grid;
            grid-template-columns: auto auto auto auto;
            grid-gap: 24px;

            @include  mobile {
                grid-template-columns: auto auto;
                grid-gap: 10px;
            }

            .show-cards {
                background: #F2FAF8;
                height: 91px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 20px;

                @include mobile {
                    height: 55px;
                    padding: 12px 27px;
                }

                .txt-holder {
                    font-size: 14px;
                    color: #0F0F0F;
                    text-transform: capitalize;
                    //font-family: 'Montserrat-Medium';

                    @include mobile {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
