/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Deactivateaccount {
    .Popup {
        &-CloseBtn {
            inset-block-start: -15px;
        }

        &-Content {
            @include mobile {
                height: 100px;
                inset-block-start: 50%;
                overflow: hidden;
                animation: slideUp 1s cubic-bezier(.075,.82,.165,1);
            }
        }

        &-Header {
            @include mobile {
                display: block;
            }
        }
    }

    &-mainblock {
        display: flex;
        justify-content: space-evenly;
        padding-block-start: 20px;

        svg {
            width: 30px;
            height: 30px;

            &:hover {
                fill: var(--primary-base-color);
            }
        }
    }
}

@keyframes slideUp {
    from {
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}


