.accessebility_main {
    width: 100%;
    max-width: 1325px;
    margin: auto;

    @include mobile {
        padding: 2% 15px;
    }

    .accessebility_inner_wrapper {
        .accessebility_inner_div {
            margin-block-end: 30px;
            
            h1 {
                margin-block-end: 10px;
                text-align: start;
                text-transform: inherit;
            }

            h2 {
                margin-block-end: 10px;
                text-align: start;
            }

            .accessebility_content {
                font-size: 16px;
            }
        }
    }
}
