/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --search-item-hover-color: #eee;
    --search-item-divider-color: #eee;
    --search-item-title-color: #000;
}

.SearchItem {
    padding: 12px;

    &::before {
        content: none;
    }

    &:hover {
        background-color: var(--search-item-hover-color);
    }

    &-CustomAttribute {
        color: var(--color-black);
        font-size: 12px;
        font-weight: 400;
        opacity: .5;
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: 80px auto;
        grid-column-gap: 12px;
        margin-block-start: 10px;
    }

    &-QuickOrderSearchItem {
        margin-block-end: 0px;
        padding-inline: 15px;

        @include mobile {
            padding-inline-start: 0;
        }

        &::before {
            display: none;
        }

        .SearchItem-Link {
            width: 100%;
            cursor: pointer;
        }

        .SearchItem-Content {
            text-align: start;
            gap: 1rem;

            .SearchItem-Sku {
                line-height: 1.4rem;
            }
        }
    }

    &-Link {
        &.inActive {
            pointer-events: none;
        }
    }

    &-ContentDetails {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 10px;
    }

    &-Content {
        display: flex;
        flex-direction: column;
        align-items: self-start;
        gap: 12px;
    }

    &-stock {
        display: flex;
        align-items: center;

        &.active {
            span {
                background: #ECFCEF;
                color: #00741B;
                font-size: 12px;
                padding: 5px;
                white-space: nowrap;
            }
        }

        &.unActive {
            span {
                background-color: #FFF5F4;
                color: var(--primary-base-color);
                font-size: 12px;
                padding: 5px;
                white-space: nowrap;
            }
        }
    }

    &-Title {
        text-transform: capitalize;
        font-weight: 700;
        text-align: start;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    &-Sku {
        font-size: 12px;
        line-height: 10px;
    }

    &-Price {
        line-height: 10px;
    }
}
