/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.SearchPage {
    &-Wrapper {
        max-width: var(--content-wrapper-width);
        padding-inline: 32px;
        margin-inline: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-block-end: 20px;
        border-bottom: 1px solid var(--primary-divider-color);

        @include mobile {
            padding-inline: 15px;
        }
    }

    &-Heading {
        text-transform: none;
        font-size: 20px;

        span {
            color: #000;
        }
    }

    &-SubHeading {
        color: #707070;
        font-size: 16px;
    }

    &-SearchRelatedList {
        display: flex;
        flex-direction: row;
        gap: 10px;
        overflow-x: auto;

        @include mobile {
            width: 100%;

            &::-webkit-scrollbar {
                height: 0px; /* for horizontal scrollbars */
            }
        }

        li {
            border: 1px solid #9D9D9D;
            border-radius: 15px;
            margin: 0;
            padding: 7px 15px;
            cursor: pointer;

            &::before {
                display: none;
            }

            @include mobile {
                white-space: nowrap;
            }
        }
    }
}
