/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ExpandableContentCategoryDescription {
    &-description {
        // display: -webkit-box;
        // -webkit-line-clamp: 3;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
        text-align: justify;
        line-height: 20px;
        
        p {
            display: inline-block;
        }

        &.active {
            -webkit-line-clamp: initial;
        }
    }

    &-knowMoreBtn {
        color: var(--primary-base-color);
        text-decoration: underline;
        cursor: pointer;

        @include desktop {
            margin-block-end: 10px;
        }
    }
}
