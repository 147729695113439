.homepage_block {
    .CategoryCard {
        max-width: 1440px;
        margin-block-start: 44px;
        margin-block-end: 1rem;
        margin-inline: auto;

        .category-section {
            margin: 0 auto;

            .category-container {
                .category-lable {
                    text-align: center;

                    .our-category {
                        font-family: $font-Montserrat;
                        font-size: 30px;
                        font-weight: 600;
                        display: none;

                        @include mobile {
                            font-size: 20px;
                        }
                    }

                    @include mobile {
                        margin-block-end: 0px;
                    }

                    @include tablet {
                        margin-block-end: 60px;
                    }
                }
            }

            .category {
                &-grid-container {
                    width: 100%;
                    align-items: center;
                    margin: 0 auto;

                    .category-grid {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        grid-template-rows: repeat(3, 1fr);
                        justify-content: center;
                        justify-items: center;
                        grid-column-gap: 2%;
                        grid-row-gap: 89px;
                        margin: 0 60px;
                        margin-inline-end: auto;

                        .category {
                            &-cart-card {
                                background-color: #f2faf8;
                                border-radius: 5px;
                                text-align: center;
                                height: 336px;
                                max-width: 422px;
                                width: 100%;
                                min-width: 188px;

                                .category {
                                    &-card-holder {
                                        .card-img {
                                            margin-block-start: -15%;
                                            height: 220px;

                                            .cardImg {
                                                object-fit: contain;
                                                max-height: 220px;
                                                min-height: 120px;

                                                @include mobile {
                                                    height: 120px;
                                                    width: 170px;
                                                }

                                                @include tablet {
                                                    height: 120px;
                                                    width: 170px;
                                                }
                                            }

                                            @include mobile {
                                                height: 126px;
                                                margin-block-start: -28%;
                                            }

                                            @include tablet {
                                                height: 130px;
                                            }
                                        }

                                        .card-lable {
                                            margin-block-start: 27px;
                                            font-family: "Lato", sans-serif;
                                            font-weight: 700;
                                            font-size: 20px;

                                            @include mobile {
                                                margin-block-start: 25px;
                                                font-size: 14px;
                                            }

                                            @include tablet {
                                                font-size: 14px;
                                                margin-block-start: 5%;
                                            }
                                        }

                                        .card-desc {
                                            margin-block-start: 12px;
                                            padding: 0 3%;
                                            font-family: "Lato", sans-serif;
                                            font-size: 14px;
                                            color: #575757;

                                            @include mobile {
                                                font-size: 13px;
                                                margin-block-start: 8px;
                                            }
                                        }

                                        .card-btn {
                                            margin-block-start: 24px;

                                            .card-button {
                                                width: 135px;
                                                height: 40px;
                                                font-family: "Lato", sans-serif;
                                                font-weight: normal;
                                                color: #000;
                                                font-size: 17px;
                                                background: none;
                                                outline: 1px solid;
                                                border: none;
                                                border-radius: 0;
                                                padding: 0;
                                                text-decoration: none;

                                                @include mobile {
                                                    height: 35px;
                                                    font-size: 14px;
                                                    border-radius: 4px;
                                                }
                                            }

                                            @include mobile {
                                                margin-block-start: 16px;
                                            }
                                        }

                                        @include mobile {
                                            min-height: 220px;
                                        }

                                        @include tablet {
                                            min-height: 220px;
                                        }
                                    }
                                }

                                &:hover {
                                    background-color: #fff;
                                    box-shadow: 1px 3px 15px #0000000f;
                                    cursor: pointer;

                                    .category-card-holder {
                                        .card-btn {
                                            .card-button {
                                                background-color: #e73b25;
                                                color: #fff;
                                                outline: none;
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                }

                                @include mobile {
                                    height: 230px;
                                    min-width: 188px;
                                    margin-block-start: 15%;
                                    margin-block-end: 2%;
                                }

                                @include tablet {
                                    min-width: 235px;
                                    height: 260px;
                                    margin-block-start: 10%;
                                }
                            }
                        }

                        @include mobile {
                            display: flex;
                            overflow-x: auto;
                            margin: 24px 0;
                            justify-content: normal;
                            flex-direction: column;

                            &::-webkit-scrollbar {
                                display: none;
                            }
                        }

                        @include tablet {
                            grid-row-gap: 40px;
                        }
                    }
                }
            }
        }

        @include mobile {
            margin-block-start: 41px;
        }
    }
}
