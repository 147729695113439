/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --breadcrumbs-height: 40px;
    --breadcrumbs-active-color: #0a0903;
    --breadcrumbs-color: #888;
}

.Breadcrumbs {
    $crumb-padding: 11px;
    $arrow-size: 4px;

    @include mobile {
        --breadcrumbs-background: #fff;

        display: block;
    }

    &-List {
        list-style: none;
        padding-block: 2rem;

        @include mobile {
            padding-block-start: 20px;
        }

        li:last-child {
            margin-block-end: 6px;

            .Breadcrumb-Link {
                span {
                    color: var(--primary-base-color);
                }
            }
        }
    }
}
