.meet_the_team_main_page {
    padding: 0% 14%;

    @include mobile() {
        padding: 0% 2%;
    }

    .meet_the_team_main {
        text-align: center;
        margin-block-end: 20px;

        .meet_the_team_content_wrapper {
            background-color: #f7f7f7;
            padding: 60px 0px;

            .meet_the_team_heading {
                margin-block-end: 20px;
            }

            .meet_the_team_content_heading {
                margin-block-end: 12px;
            }

            .meet_the_team_content {
                font-size: 16px;
            }
        }
    }
}
