/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.HomePage {
    .CmsPage {
        &-Heading {
            display: none;
        }

        &-Wrapper {
            margin-block-start: 1rem;
            padding: 0;
            max-width: 99vw;

            @include mobile {
                margin-block-end: 0;
                margin-block-start: 0;
            }
        }
    }

    .SliderWidget-FigureImage img {
        object-fit: contain;
        width: 100%;
    }

    .SliderWidget-FigureImage {
        @include desktop {
            padding-block-end: 32%;
        }

        @include mobile {
            padding-block-end: 87%;
        }
    }

    .ProductListWidget .product-slider .slick-arrow svg {
        @include desktop {
            height: 37px;
        }
    }

    .product-slider {
        .slick-slide:not(:first-of-type) {
            margin-inline-end: 24px;
    
            @include mobile {
                margin-inline-end: 16px;
            }
        }

        .slick-slider.Codilar.slick-initialized {
            @include desktop {
                height: 388px;
            }

            @include mobile {
                height: 365px;
            }
        }
    }

    .ProductListWidget-Page {
        @include desktop {
            grid-column-gap: 24px;
        }

        @include mobile {
            grid-column-gap: 16px;
        }
    }

    h2.slider-heading {
        text-align: center;
        font-family: $font-Montserrat;
        font-weight: 600;

        @include desktop {
            margin-block-start: 88px;
            font-size: 30px;
        }

        @include mobile {
            font-size: 20px;
            margin-block-start: 48px;
        }
    }

    .swiper-button-next, .swiper-button-prev {
        background: #e53d2f;
        border-radius: 50px;
        width: 40px;
        height: 40px;
        transform: unset;
        border: 0;
        box-shadow: 0px 3px 6px #00000029;
    }

    .swiper-button-next {
        @include desktop {
            inset-inline-end: 6.5rem;
        }

        &::before {
            content: ' \02C3';
            width: 40px;
            height: 40px;
            padding: 7px;
            color: #ffffff;
            position: absolute;
            inset-block-start: 2%;
            font-size: 20px;
        }
    }

    .swiper-button-prev {
        @include desktop {
            inset-inline-start: 6.5rem;
        }

        &::after {
            content: ' \02C2';
            width: 40px;
            height: 40px;
            padding: 8px;
            color: #ffffff;
            position: absolute;
            inset-block-start: 3px;
            inset-inline-start: 5px;
            font-size: 20px;
        }
    }

    .swiper-button-disabled {
        opacity: 0;
    }

    main.CmsPage .Footer {
        display: none;
    }
}
