/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --secondary-base-color: #f5f5f5;
}

.ForgotPassword {
    background-color: var(--secondary-base-color);

    .ContentWrapper {
        min-height: calc(100vh - var(--header-total-height));
        padding-block-start: 28px;
        padding-block-end: 50px;
        padding-inline: 20px;

        @include desktop() {
            padding-block-start: 25px;
        }
    }

    &-CreateAccountButton,
    &-SignInButton {
        width: 100%;
    }

    &-Input {
        &_type_email {
            width: 100%;
        }
    }

    &-InnerWrapper {
        background: #fff;
        padding: 20px;

        @include desktop() {
            margin: 40px auto 0;
            display: block;
        }

        @include mobile {
            padding: 40px 20px;
        }

        @include tablet {
            padding: 40px 20px;
            grid-template-columns: none;
        }

        .MyAccountOverlay {
            &-Additional {
                display: none;
            }
        }

        button {
            border-radius: 4px;

            @include desktop {
                margin-block-start: 20px;
                font-size: 16px;
                text-transform: capitalize;
            }
        }

        button,
        input {
            min-width: 250px;

            @include mobile {
                width: 100%;
                text-transform: capitalize;
            }
        }

        > div {
            flex: 1;
            margin: 0 10px;
        }

        p {
            margin-block-end: 20px;
        }
    }

    &-SendLinkTitleActive {
        background-color: #f2faf8;
        height: 70px;
        display: flex;
        align-items: center;
        gap: 1rem;
        padding-inline: 20px;

        @include mobile {
            padding-inline: 0;
            /* stylelint-disable-next-line declaration-no-important */
            margin: 0 !important;
        }

        p {
            margin-block-end: 0px;

            span {
                color: var(--primary-base-color);
            }
        }
    }

    &-SendLinkTitle {
        display: none;
    }

    &-ContainerWrapperContent {
        @include desktop() {
            display: grid;
            margin: 40px auto 0;
            justify-content: space-around;
            grid-gap: 1rem;
            grid-template-columns: 1fr 1fr;
        }

        @include mobile {
            padding: 0;
        }

        @include tablet {
            padding: 40px 20px;
            grid-template-columns: none;
        }

        .formContent {
            .Form {
                .Field-Wrapper {
                    .Field_type_email {
                        margin-block-end: 20px;
                    }
                }
            }
        }
    }

    &-AdditionalContent {
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
            .Fpimage {
                svg {
                    width: 150px;
                    height: 150px;
                }
            }
        }

        @include tablet {
            .Fpimage {
                svg {
                    width: 150px;
                    height: 150px;
                }
            }
        }
    }

    &-ContainerWrapper {
        @include desktop {
            padding: 20px 70px;
        }

        h3 {
            text-align: center;
            font-size: 20px;

            @include mobile {
                font-size: 16px;
            }
        }

        p {
            text-align: center;

            @include mobile {
                font-size: 12px;
                width: 100%;
                max-width: 23rem;
                margin-inline-end: auto;
                margin-inline-start: auto;
            }
        }

        form {
            max-width: 100%;
        }

        .Field {
            input {
                width: 100%;
                height: 48px;
                background-color: var(--secondary-base-color);
                box-shadow: inset 1px 1px 6px #00000014;
                border-radius: 4px;
                caret-color: #000;
                border: var(--secondary-base-color);

                &:-webkit-autofill {
                    /* stylelint-disable-next-line declaration-no-important */
                    box-shadow: inset 1px 1px 6px #00000014 !important;
                    /* stylelint-disable-next-line declaration-no-important */
                    background-color: var(--secondary-base-color) !important;
                }
            }

            &-LabelContainer {
                label {
                    font-size: 16px;
                }
            }
        }

        .Captcha-field {
            display: flex;
            align-items: center;
            margin-block-start: 20px;

            @include mobile {
                display: block;
            }
        }

        .ReloadCaptcha {
            display: flex;
            justify-content: flex-end;

            @include mobile {
                justify-content: center;
            }

            .ReloadCaptchaButton {
                color: var(--primary-base-color);
                border-bottom: 1px solid var(--primary-base-color);
                font-size: 16px;

                @include mobile {
                    min-width: 100px;
                    width: 42%;
                    border-radius: 0;
                    margin-block-start: 20px;
                }
            }
        }

        .CancelButton {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;

            .CancelBtn {
                font-size: 16px;
                color: var(--primary-base-color);
                margin-block-start: 0px;
                cursor: pointer;
            }
        }
    }

    &-SignInWrapper {
        margin-block-start: 100px;
    }

    .Loader {
        margin: 0;
    }
}
