/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CurrencySwitcher {
    border-block-end: unset;
    min-width: fit-content;
    width: 100%;

    .FieldSelect {
        width: unset;

        @include mobile {
            margin-inline-end: 2.5rem;
        }

        .ChevronIcon {
            inset-inline-end: 0;
        }

        &-Select {
            font-weight: 500;
            padding-inline-end: 14px;
        }

        &-Options {
            width: 102px;
            inset-inline-start: -21px;
        }
    }
}
