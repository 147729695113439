.about-us-page {
    padding: 0 14%;

    @include mobile {
        padding: 0% 15px;
    }

    .about-us-page-section {
        margin-block-end: 30px;

        h2 {
            margin-block-end: 10px;
            text-align: center;
        }

        p {
            margin-block-end: 4px;
            text-align: center;
        }
    }
}
