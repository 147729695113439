.Return-Refunds {
    @include mobile {
        padding: 0 20px;
    }

    h1 {
        font-weight: 700;
        line-height: 1.1;
        font-size: 2.59615385rem;
        margin-block-start: 0;
        margin-block-end: 2.88461538rem;
    }


    .ques {
        h3 {
            font-weight: 700;
            line-height: 1.1;
            font-size: 1.82692308rem;
            margin-block: 2.16346154rem 1.44230769rem;
            text-transform: inherit;
        }
    }
   
    .ans {
        p {
            margin-block: 0 1.44230769rem;
            font-size: 15px;
            line-height: 22px;
        }
    }

    h3 {
        font-weight: 700;
        line-height: 1.1;
        font-size: 1.82692308rem;
        margin-block: 2.16346154rem 1.44230769rem;
        text-transform: inherit;
    }

    p {
        margin-block: 0 1.44230769rem;
        font-size: 15px;
        line-height: 22px;
    }
}
