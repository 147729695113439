.Retailerssolution_joing-us-banner {
    .Retailerssolution_container {
        @include mobile() {
            padding: 0% 2%;
        }

        .Retailerssolution_innerwrapper {
            background-color: #74b688;
            padding: 2%;

            .Retailerssolutions_section {
                h2 {
                    font-size: 87px;
                    margin-block-end: 48px;
                    margin-block-start: 50px;
                    line-height: 1.1;
                    
                    @include mobile() {
                        font-size: 50px;
                    }
                }

                h3 {
                    font-size: 45px;
                    margin-block-end: 48px;
                    line-height: 48px;
                    color: #fff;

                    @include mobile() {
                        font-size: 20px;
                    }
                }
            }
        }

        .Retailerssolutions_section {
            margin: 30px 0px;
            text-align: center;

            a {
                background: #fff;
                color: #2d2d2d;
                border: 1px solid #fff;
                padding: 20px 30px;
                font-size: 16px;
                line-height: 32px;
                font-weight: 700;
                border-radius: 30px;
            }

            ul {
                li {
                    font-size: 17px;
                    margin-block-end: 13px;
                    color: #fff;
                }
            }
        }

        iframe {
            max-width: 100%;

            @include mobile() {
                max-width: 100%;
                width: 100%;   
                height: 300px;
            }
        }
    }
}
