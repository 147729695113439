/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.QuickOrderSearch {
    &-MultipleProducts {
        @include desktop {
            padding: 15px 0;
        }
    }

    [type='number'] {
        pointer-events: unset;
    }

    &-SectionWrapper {
        background: #fff;
        box-shadow: 1px 1px 13px #00000005;
        margin-block-end: 3rem;
        padding: 2rem;
    }

    &-SearchSku {
        padding: 2rem 0;
        border-bottom: 1px solid #9D9D9D;

        h3 {
            font-size: 16px;
            margin-block-start: 0px;
            padding-block-end: 8px;
            border-bottom: 1px solid #0F0F0F;
        }
    }

    &-ContentGrid {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        @include mobile {
            margin-block-start: 1rem;
            align-items: center;
        }

        button.Button.QuickOrderList-_ConfigButton {
            width: 200px;
            border-radius: 7px;

            @include mobile {
                width: unset;
                height: 40px;
                font-size: 12px;
            }
        }
    }

    &-contentMobileGrid {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        @include mobile {
            flex-direction: row;
            gap: 5px;
            align-items: flex-end;
        }
    }

    &-csvUploader {
        background: #fff;
        padding: 0 0 2rem 0;

        h3 {
            font-size: 16px;
            text-transform: inherit;
        }

        .csvUploadContent {
            margin-block-end: 0;
            padding-block-end: 2rem;
            border-bottom: 1px solid #0F0F0F;
        }
    }

    &-SearchColumn {
        display: flex;
        align-items: center;
        gap: 4rem;

        @include mobile {
            flex-direction: column;
            gap: 0rem;
        }

        .SearchField {
            @include desktop {
                width: 500px;
                margin: 0;
            }

            @include mobile {
                display: block;
            }

            @include tablet {
                width: 100%;
            }

            .label {
                margin-block-end: 1rem;
                font-size: 14px;
            }

            .SearchField-Input {
                background-color: #FAFAFA;
                border-radius: 3px;
                box-shadow: inset 1px 1px 6px #00000014;
                height: 42px;
                border: 1px solid #FAFAFA;

                @include mobile {
                    height: 52px;
                }

                &_isActive {
                    caret-color: #000;
                }
            }
        }

        .SearchFieldActions {
            display: flex;
            gap: 3rem;
            align-items: center;

            @include mobile {
                display: none;
                gap: 0rem;
                align-self: flex-end;
            }
        }
    }

    &-SelectedProductDetails {
        display: grid;
        grid-template-columns: 1fr 3fr 2fr;
        align-items: center;
        border-bottom: 1px solid #000;
        margin: 2rem 0;
        padding-block-end: 2rem;

        @include mobile {
            grid-template-columns: none;
        }

        .ProductImgAndDetails {
            display: flex;
            align-items: center;

            .ImgDiv {
                width: 104px;
            }

            .QuickOrderSearch-productName {
                p {
                    margin-block-end: 10px;
                    font-size: 14px;
                }

                p:nth-child(2) {
                    font-weight: bold;
                    text-transform: uppercase;
                    color: var(--primary-base-color);
                }

                p:last-child {
                    color: #707070;
                }
            }
        }

        .ProductCard-StockStatus {
            display: block;
            width: max-content;

            @include mobile {
                font-size: 16px;
                padding: 6px;
            }
        }

        .ProductQuantity,
        .ProductStockStatus {
            display: flex;
            justify-content: center;

            span {
                font-size: 14px;
            }
        }

        .ProductQuantity {
            flex-direction: column;
            align-items: center;

            p {
                span {
                    color: var(--primary-base-color);
                }
            }
        }

        .ProductStockStatus {
            &.IN_STOCK {
                span {
                    color: #00741B;
                }
            }

            &.OUT_OF_STOCK {
                span {
                    color: var(--primary-base-color);
                }
            }
        }
    }

    &-QuantityButtons {
        .label {
            margin-block-end: 1rem;
            font-size: 14px;
        }

        .QuickOrderSearch-Qty {
            margin-block-start: 0;
            display: flex;
            border: 1px solid #3a001e;
            width: 116px;
            height: 40px;
            border-radius: 4px;

            [type="number"] {
                background-color: #f2fAf8;
                min-width: 36px;
                height: 36px;
                font-size: 14px;
            }

            button {
                border: none;
                width: 35px;
                height: 38px;
            }

            [aria-label='Add'], [aria-label='Subtract'] {
                svg {
                    fill: #707070;
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }

    &-QuickOrderButton {
        .QuickOrderList-_Button {
            width: 210px;
            height: 40px;
            border: 2px solid var(--primary-base-color);
            margin-block-start: 24px;
            color: var(--primary-base-color);
            font-weight: 900;
            cursor: pointer;
            text-transform: uppercase;

            @include mobile {
                width: 180px;
                margin-block-start: 0px;
            }
        }
    }

    &-Figure {
        display: flex;
        align-items: center;
        justify-content: center;

        .QuickOrderSearch-Picture {
            width: 165px;
            padding-block-end: 85%;
        }
    }

    &-BrandLogo {
        position: absolute;
        inset-inline-end: 23px;
        width: 50px;
        height: 50px;
        inset-block-end: 10px;
    }

    &-MainInfo {
        padding-inline: 10px;

        @include mobile {
            padding-inline: 0;
        }

        .QuickOrderSelectedProductComponent-Name {
            margin-block-end: 0;
            font-size: 16px;
            font-family: 600px;
            text-transform: capitalize;
        }

        .ProductCard-SkuName {
            margin-block: 5px;
            font-size: 14px;
        }

        .ProductActions-AttributesWrapper {
            .ProductConfigurableAttributes {
                padding-block: 0;

                &-SwatchList {
                    @include desktop {
                        width: 80%;
                    }
                    
                    
                    .ProductAttributeValue {
                        &-qty {
                            display: none;

                            &_isSelected {
                                display: block;
                            }
                        }

                        &-String {
                            .outofstock-section_main {
                                .Outofstock-label {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        .PriceContentWrapper {
            @include mobile {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: baseline;
            }
        }
    }
}
