.paybybitcoin_cms_page_main {
    padding: 0% 14%;

    @include mobile {
        padding: 0 15px;
    }

    .paybybitcoin_cms_page_innerwrapper {
        padding: 2%;

        h2 {
            margin-block-end: 20px;
            text-transform: inherit;
        }

        @include mobile {
            h2,
            h1 {
                text-align: start;
            }
        }

        .Paybybitcoin_Inner_Section {
            margin-block-end: 20px;

            h2 {
                margin-block-end: 10px;
            }

            p {
                margin-block-end: 10px;
            }

            .Paybybitcoin_Redtext {
                color: #e84620;
            }
        }
    }
}
