/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MenuMobile {
    &-OverlayConatiner {
        visibility: hidden;
        opacity: 0;
        transition: left .5s ease-out,opacity .5s ease-out;

        &_isVisible {
            visibility: visible;
            width: 100%;
            background: rgba(0,0,0,.3);
            opacity: 1;
            height: 100vh;
            z-index: 1000;
            position: fixed;
            inset-inline-start: 0;
            inset-block-start: 0;
        }
    }

    &-OverlayBlock {
        visibility: hidden;
        opacity: 0;
        transition: .5s;
        inset-inline-start: -100%;
        position: fixed;
        background: white;
        height: 100vh;
        inset-block-start: 0;
        width: 0;

        &_active {
            opacity: 1;
            width: 94%;
            visibility: visible;
            inset-inline-start: 0;
            overflow-y: scroll;
            padding: 40px 30px;
            z-index: 1002;
        }

        .StoreSwitcher .FieldSelect-Select {
            @include mobile {
                padding: 0 10px 0 0;
                font-size: 16px;
            }
        }

        .CurrencySwitcher .FieldSelect-Select {
            @include mobile {
                padding: 0 6px 0 0;
                font-size: 16px;
            }
        }

        .CurrencySwitcher .FieldSelect .ChevronIcon {
            @include mobile {
                width: 10px;
                height: 10px;
            }
        }

        .StoreSwitcher .ChevronIcon {
            @include mobile {
                width: 10px;
                height: 10px;
            }
        }
    }

    &-TopMenu {
        @include mobile {
            display: flex;
            justify-content: space-between;
            padding: 0 0 30px 0;
        }

        &_isSubMenu {
            @include mobile {
                padding: 0 0 18px 0;
                border-bottom: 1px solid #F4F4F4;
            }
        }
    }

    &-CloseBtn {
        svg {
            @include mobile {
                width: 24px;
                height: 24px;
            }
        }
    }

    &-MyAccountWrapper {
        @include mobile {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        svg {
            @include mobile {
                width: 36px;
                height: 36px;
            }
        }
    }

    &-MyAccountLabel {
        @include mobile {
            display: flex;
            flex-direction: column;
            margin-inline-start: 12px;
        }

        &_NotSignedIn {
            .log-in-txt {
                @include mobile {
                    margin-block-end: 0;
                }
            }
        }

        &_SignedIn {
            .log-in-txt {
                @include mobile {
                    margin-block-end: 5px;
                }
            }
        }

        .log-in-txt {
            @include mobile {
                letter-spacing: 0px;
                color: #0F0F0F;
                font-size: 18px;
                font-weight: bold;
            }
        }

        .my-account-label {
            @include mobile {
                letter-spacing: 0px;
                color: var(--primary-base-color);
                font-size: 12px;
                text-align: start;
            }
        }
    }

    &-CloseOverlayConatiner {
        &_isVisible {
            @include mobile {
                position: static;
                width: 100vw;
                height: 100vh;
                z-index: 1001;
            }
        }
    }

    &-BottomMenu {
        @include mobile {
            padding: 36px 0;
            font-size: 16px;
            letter-spacing: 0px;
            color: #0F0F0F;
            border-top: 1px solid #F4F4F4;
        }

        a {
            @include mobile {
                color: #0F0F0F;
                font-weight: normal;
                font-size: 16px;
            }
        }

        .quick-order {
            @include mobile {
                margin-block-end: 25px;
            }
        }
    }

    &-TopMenuelem {
        @include mobile {
            display: flex;
            align-items: center;
        }

        .MobilecategoryName {
            @include mobile {
                letter-spacing: 0px;
                color: #0F0F0F;
                font-size: 16px;
                font-weight: bold;
                margin-inline-start: 12px;
            }
        }
    }
}
