.std {
    width: 100%;
    max-width: 1336px;
    margin: auto;
    margin-block-start: 6rem;

    @include mobile {
        width: unset;
        padding: 0 14px;
    }


    @include tablet {
        width: unset;
        padding: 0 14px;
    }

    .cookies_main_header {
        .cookie_header {
            font-weight: 700;
            line-height: 1.1;
            font-size: 2.59615385rem;
            margin-block-start: 0;
            margin-block-end: 2.88461538rem;
        }
    }

    .page-head {
        .page_header {
            font-weight: 700;
            line-height: 1.1;
            font-size: 1.82692308rem;
            margin-block-start: 2.16346154rem;
            margin-block-end: 1.44230769rem;
            text-align: justify;
        }
    }

    .cookies_para {
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
    }

    .page_header {
        font-weight: 700;
        line-height: 1.1;
        font-size: 1.82692308rem;
        margin-block-start: 2.16346154rem;
        margin-block-end: 1.44230769rem;
    }

    .Internet_Explorer {
        margin-inline-start: 28px;
        margin-block-end: 3.60576923rem;

        .Internet_list {
            list-style-type: disc;

            a {
                color: #e84620;
                font-weight: 400;
                line-height: 1.42857143;
                font-size: 1.4rem;
            }

            &:hover {
                text-decoration: underline;
                color: #e84620;
            }

            &::before {
                margin: 0;
                position: fixed;
            }

            &::after {
                margin: 0;
                position: fixed;
            }
        }
    }

    .Internet_header {
        font-weight: 700;
        line-height: 1.1;
        font-size: 1.4rem;
        margin-block-start: 0;
        margin-block-end: 2.88461538rem;
    }

    .Internet_Exploere {
        margin-block-start: 0;
        margin-block-end: 3.60576923rem;
        margin-inline-start: 28px;

        .Exploer_list {
            list-style-type: decimal;
            margin-block-start: 0;
            margin-block-end: 1.44230769rem;
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;

            &::before {
                position: fixed;
            }

            &::after {
                position: fixed;
            }
        }
    }

    .a-top {
        margin-block-start: 0;
        margin-block-end: 1.44230769rem;

        a {
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 1.5;
            color: #e84620;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                color: #e84620;
            }
        }
    }

    .Internet_Select {
        margin-block-start: 0;
        margin-block-end: 3.60576923rem;
        margin-inline-start: 28px;

        .select_list {
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            list-style-type: decimal;
            margin-block-start: 0;
            margin-block-end: 1.44230769rem;
        }
    }

    .Internet_Mozilla {
        margin-block-start: 0;
        margin-block-end: 3.60576923rem;
        margin-inline-start: 28px;

        .Mozilla_list {
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            list-style-type: decimal;
            margin-block-start: 0;
            margin-block-end: 1.44230769rem;
        }
    }

    .Internet_opera {
        margin-block-start: 0;
        margin-block-end: 3.60576923rem;
        margin-inline-start: 28px;

        .opera_list {
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            list-style-type: decimal;
            margin-block-start: 0;
            margin-block-end: 1.44230769rem;
        }
    }
}
