/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ContactPage {
    &-Heading {
        font-weight: 700;
        line-height: 1.1;
        font-size: 2.59615385rem;
        margin-block-start: 0;
        margin-block-end: 2.88461538rem;
    }

    &-ColumnWrapper {
        display: flex;
        margin-block-end: 24px;

        @include mobile {
            display: block;
            padding: 12px 0;
        }
    }

    &-Column {
        flex: 1;

        &_isContent {
            margin-inline-end: 18px;

            @include mobile {
                margin-inline-end: 0;
            }
        }
    }
}
