/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.OrderedItem {
    &-ProductsWrapper {
        margin-block-start: 2rem;
        padding: 3rem;
        background: #fff;

        @include mobile {
            padding-block: 3rem;
            margin-block: 0 2rem;
        }
    }

    &-Title {
        h4 {
            font-size: 16px;
            color: var(--primary-base-color);
            font-weight: bold;
            padding-block-end: 1rem;
            border-bottom: 1px solid #F2F2F2;
        }
    }

    &-Wrapper {
        border-bottom: 1px solid #9D9D9D;
        display: grid;
        grid-template-columns: minmax(100px, 3fr) 2fr 1fr;
        align-items: center;

        @include mobile {
            grid-template-columns: none;
        }
    }

    &-productQtyMobile {
        @include desktop {
            display: none;
        }

        @include mobile {
            display: flex;
            align-items: center;
            gap: 25px;

            .totalQty,
            .rowTotal {
                display: flex;
                gap: 10px;

                p {
                    color: #707070;
                    margin-block-end: 0;
                }
            }
        }
    }

    &-productInfo {
        display: flex;
        align-items: center;
        gap: 12px;
        padding-block: 2rem;

        .Image {
            width: 25%;

            @include mobile {
                width: 104px;
            }
        }

        .productContent {
            display: flex;
            flex-direction: column;
            gap: 14px;

            .info {
                font-size: 14px;
            }

            .productSku {
                text-transform: uppercase;
                color: #707070;

                span {
                    @include mobile {
                        font-size: 12px;
                    }
                }
            }

            .productName {
                font-weight: 600;
                text-transform: capitalize;
                width: 83%;

                @include mobile {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }

    &-productQty {
        .totalQty {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            p {
                margin-block-end: 7px;
            }

            span {
                font-weight: bold;
                font-size: 14px;
            }
        }

        @include mobile {
            display: none;
        }
    }

    &-productRowTotal {
        .rowTotal {
            display: flex;
            justify-content: flex-end;

            p {
                font-weight: bold;
            }
        }

        @include mobile {
            display: none;
        }
    }
}
